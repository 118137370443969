import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import "./style.css";
import moment, { Moment } from "moment";
import leftArrow from "../../assets/leftArrow.png";
import rightArrow from "../../assets/rightArrow.png";
import ShiftDetails from "../modals/shiftDetails";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";

interface Day {
  date: Moment;
}

interface Week {
  days: Day[];
}

interface Schedule {
  id: number;
  date: string;
  deptId: number;
  shift: {
    id: number;
    start_time: string;
    end_time: string;
    shift_type: string;
  };
}

const colors = [
  "#c0b0ff",
  "#a5eebe",
  "#fcee71",
  "#f6abd8",
  "#f28585",
  "#fdd484",
  "#b8d9ff",
];

const ScheduleTable = () => {
  const [shiftDetailsModal, setShiftDetailsModal] = useState<
    boolean | string | number
  >(false);
  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment());
  const [weeksInMonth, setWeeksInMonth] = useState<Week[]>([]);
  const { t, i18n } = useTranslation();
  const daysOfWeek = [
    t("Monday"),
    t("Tuesday"),
    t("Wednesday"),
    t("Thursday"),
    t("Friday"),
    t("Saturday"),
    t("Sunday"),
  ];

  const [schedule, setSchedule] = useState<Schedule[]>([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateto, setDateto] = useState("");

  const fetchschedules = async () => {
    const firstDayOfCurrentMonth = currentDate.clone().startOf("month").format("YYYY-MM-DD");
    const firstDayOfNextMonth = currentDate.clone().add(1, "month").startOf("month").format("YYYY-MM-DD");
    try {
      const data = await fetchWithToken(`/schedules?startDate=${firstDayOfCurrentMonth}&endDate=${firstDayOfNextMonth}`, {
        method: "GET",
      });
      setSchedule(data?.data || []);
    } catch (error) {
      console.error("Failed to fetch schedules:", error);
    }
  };

  useEffect(() => {
    fetchschedules();
  }, [dateFrom, dateto]);

  useEffect(() => {
    const firstDayOfMonth = currentDate.clone().startOf("month");
    const lastDayOfMonth = currentDate.clone().endOf("month");

    const startDate = firstDayOfMonth.clone().startOf("isoWeek");
    const endDate = lastDayOfMonth.clone().endOf("isoWeek");

    const weeksArray: Week[] = [];
    let day = startDate.clone();
    let week: Day[] = [];

    while (day <= endDate) {
      week.push({ date: day.clone() });
      day.add(1, "day");
      if (week.length === 7) {
        weeksArray.push({ days: week });
        week = [];
      }
    }

    if (week.length > 0) {
      weeksArray.push({ days: week });
    }

    setWeeksInMonth(weeksArray);
  }, [currentDate]);

  return (
    <div className="h-full">
      <div className="flex items-center mt-[24px] mb-[26px] xl:h-[40px] h-auto justify-between">
        <div className="text-[24px] font-[700] mr-[10%]">
          {currentDate.format("MMMM YYYY")}
        </div>
        <div className="flex">
          <div
            className="flex items-center justify-center w-[40px] h-[40px] rounded-[4px] cursor-pointer mx-3"
            onClick={() => {
              setCurrentDate(currentDate.clone().subtract(1, "month"));
              setDateFrom(
                currentDate.clone().subtract(1, "month").format("DD-MM-YYYY")
              );
            }}
          >
            <img alt="leftArrow" src={leftArrow} className="w-[8px]" />
          </div>
          <div
            className="flex items-center justify-center w-[40px] h-[40px] rounded-[4px] cursor-pointer mx-3"
            onClick={() => {
              setCurrentDate(currentDate.clone().add(1, "month"));
              setDateto(
                currentDate.clone().add(1, "month").format("DD-MM-YYYY")
              );
            }}
          >
            <img alt="rightArrow" src={rightArrow} className="w-[8px]" />
          </div>
        </div>
      </div>
      <div className="relative">
        <ShiftDetails
          isModalVisible={shiftDetailsModal}
          setModalVisible={setShiftDetailsModal}
        />
        <Table className={"w-[100%]"}>
          <TableHead>
            <TableRow >
              {daysOfWeek.map((date, index) => (
                <TableHeader
                  key={date}
                  className="xl:w-[120px] xl:min-w-[120px] xl:max-w-[120px] w-[20px] min-w-[20px] max-w-[20px]"
                  style={{
                    border: 0,
                    padding: "5px",
                  }}
                >
                  <div className="xl:w-full w-[40px] text-[black] xl:h-[40px] h-[30px] flex flex-col items-center justify-center rounded-[4px] bg-[#e8ebe9]">
                    <div className="xl:text-[14px] text-[7px] font-[600]">
                      {daysOfWeek[index]}
                    </div>
                  </div>
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody >
            {weeksInMonth?.map((week, weekIndex) => (
              <TableRow key={weekIndex} >
                {week?.days?.map((day, index) => {
                  // Find matching schedule for the date
                  const matchingSchedule = schedule?.find((s) =>
                    moment(s?.date).isSame(day?.date, "day")
                  );
                  return (
                    <TableCell
                      key={index}
                      style={{
                        border: 0,
                        padding: 0,
                        width: "20px",
                      }}
                    >
                      {moment(day?.date)?.isSame(currentDate, "month") ? (
                        <div className="flex relative rounded-[4px] w-[45px] min-w-[45px] max-w-[45px] lg:w-[140px] lg:min-w-[140px] lg:max-w-[140px] " >
                          <div
                            onClick={() => setShiftDetailsModal(matchingSchedule?.id ?? false)}
                            style={{
                              background: colors[index % colors.length],
                              opacity:
                                day?.date?.diff(moment(), "d") < 0 ? 0.3 : 0.9,
                            }}
                            className={`cursor-pointer rounded-[3px] w-full lg:h-[85px] h-[55px] flex flex-col pt-2 pl-3 m-1 relative`}
                          >
                            <div className="font-[600] text-[12px]">
                              {day?.date?.format("DD")}
                            </div>
                            {matchingSchedule && (
                              <div
                                className="font-[600] lg:text-[16px] text-[8px] text-wrap text-black"
                              >
                                {`${moment(matchingSchedule?.shift?.start_time, "HH:mm:ss")?.format("ha")} - ${moment(matchingSchedule?.shift?.end_time, "HH:mm:ss")?.format("ha")}`}
                                <div className="text-[15px] lg:flex hidden">
                                  {matchingSchedule?.shift?.shift_type}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center">
                          <div
                            className={`bg-[#f7f8f7] rounded-[3px] w-full lg:h-[85px] h-[55px] italic flex flex-col justify-center pl-3 m-1 relative`}
                          />
                        </div>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ScheduleTable;

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-dropdown/style.css";
import view from "../../assets/view.png";
import hide from "../../assets/hide.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";

interface userProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
  fetchUsers: () => void;
}

const UserModal: any = ({
  isModalVisible,
  setModalVisible,
  fetchUsers,
}) => {
  const isAdd = isModalVisible === true;
  const [visible, setVisible] = useState(false);
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      email: "",
    },
    validationSchema: Yup?.object({
      username: Yup?.string()?.required(t("Required")),
      password: Yup?.string().required(t("Required")),
      email: Yup?.string()
        ?.email(t("Invalid email address"))
        ?.required(t("Required")),
    }),
    
    onSubmit: async (values) => {
      try {
        await fetchWithToken(isAdd ? "/users" : `/users/${isModalVisible}`, {
          method: isAdd ? "POST" : "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: values?.username,
            password: values?.password,
            email: values?.email,
          }),
        });
        setModalVisible(!isModalVisible);
        fetchUsers();
      } catch (error) {
        console.error (t("Error creating user:"), error);
      }
    },
  });

  const getUserDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/users/${id}`, {
        method: "GET",
      });
      formik?.setFieldValue("username", data?.username);
      formik?.setFieldValue("password", data?.password);
      formik?.setFieldValue("email", data?.email);
    } catch (error) {
      console.error (t("Failed to fetch user:"), error);
    }
  };

  useEffect(() => {
    formik?.resetForm();
    if (
      typeof isModalVisible === "number" ||
      typeof isModalVisible === "string"
    )
      getUserDetails(isModalVisible);
  }, [isModalVisible]);

  return (
    isModalVisible && (
      <main
        onClick={() => setModalVisible(!isModalVisible)}
        className="fixed top-0 bottom-0 left-0 right-0 
        bg-black bg-opacity-30 flex justify-center items-center"      >
          
        <div className="container my-auto">
          <div
            onClick={(e) => e?.stopPropagation()}
            className="py-5 max-w-[90%] h-auto sm:max-w-[60%] sm:h-[70%] 
            overflow-auto m-auto w-full sm:w-[385px] capitalize 
            bg-[#FFF] rounded-[8px] flex flex-col items-center scrollbar-hidden"          >
            <div className="text-center text-lg font-bold">{t("User2")}</div>

            <div className="text-sm text-[#101010]">
              <div className="font-bold">{t("Name")}</div>
              <input
                type="text"
                placeholder={t("NameLabel")}
                name="username"
                required
                className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
                id="username"
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values?.username}
                style={{
                  borderColor:
                    formik?.touched?.username && formik?.errors?.username
                      ? "#E23121"
                      : "#5D6561",
                }}
              /> 
              <div className="font-bold pt-2">{t("Password")}</div>

              <div className="w-[350px] h-[40px] border rounded-[8px] border-[#101010] flex">
                <input
                  type={visible ? "text" : "password"}
                  placeholder={t("PasswordLabel")}
                  name="password"
                  required
                  className="w-[315px] placeholder-[#5D6561] p-2 my-2 outline-none"
                  id="password"
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  value={formik?.values?.password}
                  style={{
                    borderColor:
                      formik?.touched?.password && formik?.errors?.password
                        ? "#E23121"
                        : "#5D6561",
                  }}
                />
                {visible ? (
                  <img
                    src={hide}
                    alt="hide"
                    onClick={() => setVisible(!visible)}
                    className="w-5 h-5 mt-2"
                  />
                ) : (
                  <img
                    src={view}
                    alt="view"
                    onClick={() => setVisible(!visible)}
                    className="w-5 h-5 mt-2"
                  />
                )}
              </div>
              <div className="font-bold pt-2">{t("Email")}</div>
              <input
                type="email"
                placeholder={t("EmailLabel")}
                name="email"
                required
                className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-5 my-2  outline-none"
                id="email"
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values?.email}
                style={{
                  borderColor:
                    formik?.touched?.email && formik?.errors?.email
                      ? "#E23121"
                      : "#5D6561",
                }}
              />
            </div>
            <div className="w-[350px]">
              <button
                type="button"
                onClick={() => setModalVisible(false)}
                className="w-[168px] h-[40px] rounded-[8px] border hover:border-[#E23121] hover:text-[#E23121] border-[#00a843] text-[#00a843] text-[16px] font-[700] px-[24px] py-[8px]"
              >
                {t("Cancel")}
              </button>
              <button
                type="submit"
                onClick={(e: any) => {
                  formik.handleSubmit();
                }}
                className="w-[168px] rounded-[8px] bg-[#56b77b] hover:bg-[#00A843] text-[#F8FAF8] p-2 text-[16px] mt-5 px-[24px] py-[8px] ml-[13px]"
              >
                 {t("Confirm")}
              </button>
            </div>
          </div>
        </div>
      </main>
    )
  );
};

export default UserModal;
import React from "react";
import moment from "moment";
import stethoscope from "../../assets/stethoscope.png";
import locationImg from "../../assets/location.png";
import darkclose from "../../assets/darkClose.png";
import FailToast from "./failToast";
import SuccessToast from "./successToast";
import fetchWithToken from "../../utils/api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface ShiftDetailProps {
  date: string;
  start_time: string;
  end_time: string;
  shift_type: string | null;
  department: string;
  location: string;
  schedId: string;
  ShiftId: string;
  locId: string;
  deptId: string;
}

interface ProfileProps {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data: ShiftDetailProps;
}

const ScheduledShiftDetails: any = ({
  isModalVisible,
  setModalVisible,
  data,
}) => {
  const { t } = useTranslation();
  const userDepartment = useSelector((state: any) => state.user.user?.department?.dept_name);
  const handleSwapRequest = async () => {
    try {
      const response = await fetchWithToken("/swapRequests/enterswap/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          current_sch: data?.schedId,
          desired_shift: data?.ShiftId,
          desired_date: data?.date,
          location: data?.locId,
          department: data?.deptId,
          req_message: "from card",
        }),
      });
      setModalVisible(!isModalVisible);
      if (!response?.message) {
        SuccessToast("Created", "swap request created successfully", t);
      } else {
        FailToast(response?.message, t); // Pass the translation function here
      }
    } catch (error) {
      FailToast("Update profile failed. Please try again.", t); // Pass the translation function here
      //console.error("Error updating profile", error);
      console.error(t("ErrorLog"), error);
      setModalVisible(!isModalVisible);
    }
  };
  return (
    isModalVisible && (
      <main
        className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center z-[1]"
        onClick={() => setModalVisible(false)}
      >
        <div
          className="py-5 w-[270px] h-[280px] relative overflow-auto m-auto capitalize bg-[#FFF] rounded-[8px] flex flex-col items-center scrollbar-hidden shadow-[1px_2px_6px_0px_#282C2B14] "
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="flex align-middle justify-center gap-6">
            <div className="py-1 text-center text-base font-bold">
              {t("ShiftDetails")}
            </div>
            <div
              className="cursor-pointer absolute right-2"
              onClick={() => setModalVisible(false)}
            >
              <img src={darkclose} alt="close" />
            </div>
          </div>
          <div className="text-sm mt-1 text-[#101010] w-full">
            <div className="px-1 mx-[14px] text-base font-semibold mb-1 text-[#161918]">
              {data?.shift?.shift_type}
            </div>
            <div className="px-1 flex justify-between mb-2 mx-[14px]">
              <div className="w-[170px] flex flex-col justify-between">
                <div className="text-[12px] flex items-center w-full">
                  <img alt="calender" src={require("../../assets/calender.png")} className="w-[13px]" />
                  <div className="text-[16px] ml-3">
                    {moment(data?.date).format("ddd, DD MMM YYYY")}
                  </div>
                </div>
              </div>
            </div>
            <div className="px-1 flex justify-between mx-[14px]">
              <div className="w-[170px] flex flex-col justify-between">
                <div className="text-[12px] flex items-center w-full">
                  <img alt="clock" src={require("../../assets/clock.png")} className="w-[13px]" />
                  <div className="text-[16px] ml-3">
                    {`${moment(data?.shift?.start_time, "HH:mm:ss").format(
                      "h:mm A"
                    )} - ${moment(data?.shift?.end_time, "HH:mm:ss").format(
                      "h:mm A"
                    )}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="px-1 m-3 mx-[14px]">
              <div className="flex">
                <img
                  src={stethoscope}
                  alt=""
                  className="w-[13px] h-[13px] my-auto"
                />
                <div className="text-base text-[#333937] ml-3">
                  {userDepartment || "No Department"}
                </div>
              </div>
              <div className="flex">
                <img
                  src={locationImg}
                  alt=""
                  className="w-[13px] h-[13px] my-auto"
                />
                <div className="text-base text-[#333937] ml-3">
                  {data?.location || "No Location"}
                </div>
              </div>
            </div>
          </div>
          <div className="w-[80px]">
            <button
              onClick={handleSwapRequest}
              className="rounded-[8px] bg-[#00A843] hover:bg-[#389365] text-[#F8FAF8] p-2 text-[16px] mt-5 px-[24px] py-[8px] mx-auto"
            >
              {t("Swap")}
            </button>
          </div>
        </div>
      </main>
    )
  );
};

export default ScheduledShiftDetails;

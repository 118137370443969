import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select, { SingleValue } from "react-select";
import checkbox from "../../assets/checkbox.svg";
import checkmark from "../../assets/checkmark.svg";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import AnimatedBtn from "../animatedBtn";

interface Shift {
  id: number;
  start_time: string;
  end_time: string;
  shift_type: string;
  value: number;
  label: string;
}

interface Location {
  id: number;
  hospital_name: string;
  addr_one: string;
  addr_two: string;
  city: string;
  state: string;
  country: string;
  postal_code: number;
  createdAt: string;
  updatedAt: string;
  value: number;
  label: string;
}

interface Department {
  id: number;
  dept_name: string;
  locationId: number;
  createdAt: string;
  updatedAt: string;
  location: Location;
  value: number;
  label: string;
}

interface User {
  id: number;
  username: string;
  password: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  value: number;
  label: string;
  emp_id: number;
  User: any;
}

interface ProfileProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
  data?: any;
}

const SwapShift: any = ({
  isModalVisible,
  setModalVisible,
  data,
}) => {
  const { t, i18n } = useTranslation();
  const [departments, setDepartments] = useState<Department[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [desshifts, setDesShifts] = useState<Shift[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isSickDay, setIsSickDay] = useState<boolean>(false);
  const [isDecline, setIsDecline] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    currentShift: null,
    desiredShift: null,
    date: "",
    location: null,
    department: null,
    coworker: null,
    req_message: "",
  });
  const [status, setStatus] = useState<string>("");

  useEffect(() => {
    if (data) {
      setInitialValues({
        currentShift: null,
        date: data.date,
        location:
          locations.find((loc) => loc.id === data.department?.locationId) ||
          null,
        department: departments.find((dep) => dep.id === data.deptId) || null,
        coworker: null,
        req_message: "",
      });
    } else {
      setInitialValues({
        currentShift: null,
        date: "",
        location: null,
        department: null,
        coworker: null,
        req_message: "",
      });
    }
    if (isSickDay) {
      setInitialValues((prev: any) => ({
        ...prev,
        desiredShift: null,
      }));
    }
  }, [data, isSickDay]);

  const formik = useFormik<{
    currentShift: Shift | null;
    desiredShift: Shift | null;
    date: string;
    location: Location | null;
    department: Department | null;
    coworker: User | null;
    req_message: string;
  }>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup?.object({
      currentShift: Yup.object({
        value: Yup.string()?.required("Value is required"),
      }),
      desiredShift: isSickDay
        ? Yup.mixed().nullable()
        : Yup.object({
            value: Yup.string().required("Value is required"),
          }),
      date: Yup.date().required("Required").typeError("Invalid date format"),
      location: Yup.object({
        value: Yup.string().required("Value is required"),
      }),
      department: Yup.object({
        value: Yup.string().required("Value is required"),
      }),
      req_message: Yup?.string(),
    }),
    onSubmit: async (values) => {
      const payload = {
        current_sch: values?.currentShift?.id,
        desired_date: values?.date,
        location: values?.location?.id,
        department: values?.department?.id,
        ...(values?.coworker?.emp_id && {
          coworker: values?.coworker?.emp_id,
          req_message: values?.req_message,
        }),
        ...(!isSickDay && {
          desired_shift: values?.desiredShift?.id,
        }),
      };
      setStatus("onclic");
      try {
        const response = await fetchWithToken(`/swapRequests/enterswap/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        setModalVisible(!isModalVisible);
        setStatus("success");
        if (response?.message) {
          // FailToast(response?.message);
        } else {
          // SuccessToast("Requested", `Your request has been sent`);
        }
      } catch (error) {
        setStatus("fail");
        // FailToast("Failed to send swap request. Please try again.");
        console.error("Error updating shift swap", error);
      }
    },
  });

  const fetchTheShifts = async () => {
    try {
      const data = await fetchWithToken("/shifts", {
        method: "GET",
      });

      setDesShifts(
        data?.map((each: Shift) => ({
          ...each,
          value: each?.id,
          label:
            each?.shift_type === null
              ? "No Shift Type (Null)"
              : each.shift_type,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch shifts:", error);
    }
  };

  const fetchShifts = async () => {
    try {
      const data = await fetchWithToken("/schedules/getcurrentSch", {
        method: "GET",
      });

      setShifts(
        data?.upcoming_shifts?.map((each: Shift) => ({
          ...each,
          value: each?.id,
          label:
            each?.shift_type === null
              ? "No Shift Type (Null)"
              : each.shift_type,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch shifts:", error);
    }
  };

  const fetchLocations = async () => {
    try {
      const data = await fetchWithToken("/locations", {
        method: "GET",
      });

      setLocations(
        data?.map((each: Location) => ({
          ...each,
          value: each?.id,
          label: each?.hospital_name,
        }))
      );
    } catch (error) {
      console.error (t("Failed to fetch locations:"), error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const data = await fetchWithToken("/departments", {
        method: "GET",
      });

      setDepartments(
        data.data.map((each: Department) => ({
          ...each,
          value: each?.id,
          label: each?.dept_name,
        }))
      );
    } catch (error) {
      console.error (t("Failed to fetch departments:"), error);
    }
  };

  const fetchUsers = async () => {
    try {
      const data = await fetchWithToken("/profiles", {
        method: "GET",
      });
      setUsers(
        data?.map((each: User) => ({
          ...each,
          value: each?.emp_id,
          label: each?.User?.username,
        }))
      );
    } catch (error) {
      console.error (t("Failed to fetch users:"), error);
    }
  };

  useEffect(() => {
    fetchShifts();
    fetchTheShifts();
    fetchLocations();
    fetchDepartments();
    fetchUsers();
  }, []);

  useEffect(() => {
    if (isModalVisible) {
      formik.resetForm();
    }
  }, [isModalVisible]);

  return (
    isModalVisible && (
      <main
        className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center align-middle z-[1]"
        onClick={() => setModalVisible(!isModalVisible)}
      >
        <div
          className="py-5 w-full max-w-md h-[80%] overflow-auto m-auto capitalize bg-[#FFF] rounded-[8px] flex flex-col items-center scrollbar-hidden"
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="h-[40px] w-[190px] text-center text-lg font-bold mb-5">
            <button
              onClick={() => setIsSickDay(false)}
              className={`w-[90px] p-[2px] rounded ${isSickDay ? "bg-[#F7F8F7] text-[#101010]" : "bg-[#56B77B] text-white"}`}
            >
             {t("Title8")}
            </button>
            <button
              onClick={() => setIsSickDay(true)}
              className={`w-[90px] p-[2px] rounded ${isSickDay ? "bg-[#56B77B] text-white" : "bg-[#F7F8F7] text-[#101010]"}`}
            >
              {t("Title9")}
            </button>
          </div>
          <div className="text-sm text-[#101010] w-full px-5">
            <div className="font-bold"> {t("Current Shift")}</div>
            <Select
              options={shifts}
              value={formik.values?.currentShift}
              name={t("CurrentShiftTitle")}
              onChange={(option) => {
                formik.setFieldValue("currentShift", option);
                // fetchShift(option?.value, "CURRENT");
              }}
              onBlur={formik.handleBlur}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.currentShift &&
                formik?.errors?.currentShift && (
                  <div>{formik?.errors?.currentShift}</div>
                )}
            </div>
            {!isSickDay && (
              <div>
                <div className="font-bold">{t("Desirable Shift")}</div>
                <Select
                  options={desshifts}
                  value={formik.values?.desiredShift}
                  name={t("DesirableShiftTitle")}
                  onChange={(option) => {
                    formik.setFieldValue("desiredShift", option);
                    // fetchShift(option?.value, "DESIRED");
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
                  {formik?.touched?.desiredShift &&
                    formik?.errors?.desiredShift && (
                      <div>{formik?.errors?.desiredShift}</div>
                    )}
                </div>
              </div>
            )}
            {/* )} */}
            <div className="font-bold">{t("Date")}</div>
            <input
              type="date"
              placeholder={t("DateTitle")}
              name="date"
              required
              className="w-full h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="date"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.date}
              style={{
                borderColor:
                  formik?.touched?.date && formik?.errors?.date
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] w-full text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.date && formik?.errors?.date && (
                <div>{formik?.errors?.date}</div>
              )}
            </div>
            <div className="font-bold">{t("Location")}</div>
            <Select
              options={locations}
              value={formik.values?.location}
              name="location"
              onChange={(option) => formik.setFieldValue("location", option)}
              onBlur={formik.handleBlur}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.location && formik?.errors?.location && (
                <div>{formik?.errors?.location}</div>
              )}
            </div>
            <div className="font-bold">{t("Department")}</div>
            <Select
              options={departments}
              value={formik?.values?.department}
              name="department"
              onChange={(option) => formik.setFieldValue("department", option)}
              onBlur={formik.handleBlur}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.department && formik?.errors?.department && (
                <div>{formik?.errors?.department}</div>
              )}
            </div>
            <div className="flex float-end">
              <img
                src={isChecked ? checkmark : checkbox}
                alt="colleague request"
                onClick={() => setIsChecked(!isChecked)}
                className="w-[20px] h-[20px] rounded border"
              />
              <div className="text[#101010] font-semibold text-sm ml-1">
               {t("ColleagueRequest")}
              </div>
            </div>
            {isChecked && (
              <div>
                <div className="font-bold mt-7">{t("Co-Worker")}</div>
                <Select
                  options={users}
                  value={formik?.values?.coworker}
                  name="coworker"
                  onChange={(option) =>
                    formik.setFieldValue("coworker", option)
                  }
                  onBlur={formik.handleBlur}
                />
                <div className="font-bold">{t("Message2")}</div>
                <input
                  type="text"
                  placeholder={t("MessageTitle")}
                  name="req_message"
                  required
                  className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
                  id="req_message"
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  value={formik?.values?.req_message}
                />
              </div>
            )}
          </div>
          <div className="w-[350px] flex gap-3 mt-3">
            <button
              type="button"
              onClick={() => {
                setModalVisible(false);
                setIsDecline(true);
              }}
              className={`w-[168px] h-[40px] rounded-[8px] border border-[#00A843] text-[#389365] hover:border-[#1F7549] hover:text-[#1F7549] ${
                isDecline ? "text-[#56B77B] border-[#56B77B]" : ""
              } text-[16px] font-[700] px-[24px] py-[8px]`}
            >
              {t("Cancel")}
            </button>
            <div className="w-[168px]">
            <AnimatedBtn
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                formik.handleSubmit();
              }}
              txt={t("Submit")}
              status={status}
              setStatus={setStatus}
              />
              </div>
          </div>
        </div>
      </main>
    )
  );
};

export default SwapShift;

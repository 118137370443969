import React, { useState, useEffect } from "react";
// import fetchWithToken from "@/utils/api";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import { useTranslation } from 'react-i18next';
import fetchWithToken from "../../utils/api";

interface AddNoteModalProps {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  id: number | undefined;
}

const AddNoteModal: React.FC<AddNoteModalProps> = ({
  isModalVisible,
  setModalVisible,
  id,
}) => {
  const [note, setNote] = useState("");
  const [buttonText, setButtonText] = useState("Add Note");
  const [animate, setAnimate] = useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (isModalVisible) {
      setButtonText("Add Note");
      setNote("");
      setAnimate(false);
    }
  }, [isModalVisible]);

  const handleAddNote = async () => {
    try {
      const response = await fetchWithToken(`/swapRequests/enternotes/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ notes: note }),
      });
      setButtonText("Success");
      setAnimate(true);
      setTimeout(() => {
        setModalVisible(false);
        setButtonText("Add Note");
        setAnimate(false);
      }, 1000);
      // SuccessToast("Sent", `Your message has been sent`);
    } catch (error) {
      // FailToast("Failed to send your message. Please try again.");
      //console.error("Add note failed :", error);
      console.error(t("Add note failed:"), error);

    }
  };

  return (
    <>
      {isModalVisible && (
        <main
          className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center align-middle z-[1]"
          onClick={() => setModalVisible(!isModalVisible)}
        >
          <div
            className="py-2 xl:w-[30%] w-[90%] h-[30%] z-[9999] overflow-auto m-auto capitalize bg-[#FFF] rounded-[8px] flex flex-col items-center scrollbar-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="py-3 text-center text-lg font-bold">{t("Title11")}</div>
            <textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder={t("Message3")}
              className="w-[90%] h-[70%] p-2 border rounded"
            />
            <div className="flex justify-end w-[90%] mt-3">
              <button
                onClick={() => setModalVisible(false)}
                className="px-4 border border-1 py-2 border-[#00a843] text-[#00a843] mr-2 rounded-[8px]"
              >
                {t("Cancel")}
              </button>
              <button
                onClick={handleAddNote}
                className={`w-[174px] h-[40px] rounded-[8px] text-[#F8FAF8] text-[16px] px-[24px] py-[8px] mr-2 ${animate ? "animate-success" : "bg-[#56b77b] hover:bg-[#00A843]"}`}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default AddNoteModal;

import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select, { SingleValue } from "react-select";
import moment from "moment";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { updateUserProfile } from "../../utils/token";
interface ProfileProps {
  isModalVisible: any;
  setModalVisible: any;
  fetchProfiles?: any;
}

const Profile: React.FC<ProfileProps> = ({
  isModalVisible,
  setModalVisible,
}) => {
  const fileInputRef = useRef(null);
  const [userdata, setUserdata] = useState<any | null | undefined>(null);
  const [departments, setDepartments] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  const [positions, setPositions] = useState<any>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isDecline, setIsDecline] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const formik = useFormik<{
    first_name: string | undefined;
    last_name: string | undefined;
    birthdate: string;
    contact: string | undefined;
    email: string;
    deptId: any | null;
    positionId: any | null | undefined;
    roleId: any | null | undefined;
  }>({
    initialValues: {
      first_name: userdata?.first_name,
      last_name: userdata?.last_name,
      birthdate: userdata?.birthdate
        ? moment(userdata?.birthdate).format("YYYY-MM-DD")
        : "",
      email: userdata?.email ?? "",
      deptId: departments?.filter((each) => each?.id === userdata?.department_id)[0],
      positionId: positions?.filter(
        (each) => each?.id === userdata?.position_id
      )[0],
      contact: userdata?.contact,
      roleId: roles?.filter((each) => each?.id === userdata?.role_id)[0],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      first_name: Yup.string().required(t("Required")), // Adding t for translation
      last_name: Yup.string().required(t("Required")),
      contact: Yup.string().required(t("Required")),
      birthdate: Yup.string().required(t("Required")),
      email: Yup.string()
        .email(t("EmailInvalid")) // Using the translated message for invalid email
        .required(t("Required")),
      deptId: Yup.object({
        value: Yup.string().required(t("RequiredValidation")), // Using t for Value is required
      }),
      positionId: Yup.object({
        value: Yup.string().required(t("RequiredValidation")),
      }),
      roleId: Yup.object({
        value: Yup.string().required(t("RequiredValidation")),
      }),
    }),

    onSubmit: async (values) => {
      try {
        const response = await fetchWithToken(
          `/users/${userdata?.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              // ...values,
              // dept: values?.deptId?.value,
              // position: values?.positionId?.value,
              // role: values?.roleId?.value,
              "email": values?.email,
              "first_name": values?.first_name,
              "last_name": values?.last_name,
              "department_id": values?.deptId?.id,
              "position_id": values?.positionId?.id,
              "role_id": values?.roleId?.id,
              "contact": values?.contact,
              birthdate: moment(values?.birthdate).format("DD-MM-YYYY"),
              // "profile_picture": "asdfsdfa"
            }),
          }
        );
        setModalVisible(!isModalVisible);
        updateUserProfile(response);
        // SuccessToast("Updated", `Your profile has been updated`);
      } catch (error) {
        // FailToast("Update profile failed. Please try again.");
        console.error("Error updating profile", error);
      }
    },
  });

  const fetchDepartments = async () => {
    try {
      const data = await fetchWithToken("/departments", {
        method: "GET",
      });

      setDepartments(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?.id,
          label: each?.dept_name,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch departments:", error);
    }
  };
  const fetchPositions = async () => {
    try {
      const data = await fetchWithToken("/positions", {
        method: "GET",
      });

      setPositions(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?.id,
          label: each?.position_name,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch positions:", error);
    }
  };
  const fetchRoles = async () => {
    try {
      const data = await fetchWithToken("/roles", {
        method: "GET",
      });

      setRoles(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?.id,
          label: each?.name,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch swap requests:", error);
    }
  };

  const fetchuserdata = async () => {
    try {
      const data = await fetchWithToken("/users/me", {
        method: "GET",
      });

      setUserdata(data?.data);
    } catch (error) {
      console.error("Failed to fetch next shift:", error);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      fetchuserdata();
      fetchDepartments();
      fetchPositions();
      fetchRoles();
    }
  }, [isModalVisible]);

  return (
    isModalVisible && (
      <main
        className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-[1] p-4"

        onClick={() => setModalVisible(!isModalVisible)}
      >
        <div
          className="bg-white rounded-lg w-full max-w-md max-h-[90vh] overflow-auto flex flex-col items-center" onClick={(e) => e?.stopPropagation()}
        >
          <div className="relative w-full mb-5">
            <div className="text-center text-lg font-bold">
              {t("Profile")}
            </div>
            <img
              alt="close"
              src={require("../../assets/close.png")}
              onClick={() => setModalVisible(!isModalVisible)}
              className="absolute right-4 top-1 w-[20px] cursor-pointer"
            />
          </div>
          {userdata?.id && (
            <div className="relative flex items-center justify-center">
              <img
                src={userdata?.profilePicture ?? require("../../assets/noProfile.png")}
                // src={userdata?.profilePicture}
                alt="ptofile"
                className="w-[87px] h-[87px] rounded-full"
                width={90}
                height={90}
              />
              <div
                className="w-[40px] h-[40px] absolute bg-[#F8FAF8] rounded-[4px] -bottom-2 -right-2"
                // @ts-ignore
                onClick={() => fileInputRef?.current?.click()}
              >
                <img src={require("../../assets/editProfile.png")} alt="edit" className="p-2" />
                <input
                  ref={fileInputRef}
                  className="hidden"
                  type="file"
                  onChange={async (e: any) => {
                    console?.log(e?.target?.files[0]);
                    try {
                      const formData = new FormData();
                      formData.append("profilePicture", e?.target?.files[0]);
                      const data = await fetchWithToken(
                        "/uploads/uploadProfilePicture",
                        {
                          method: "POST",
                          body: formData,
                        }
                      );
                      fetchuserdata();
                    } catch (error) {
                      console.error("Failed to Schedule:", error);
                    }
                  }}
                />
              </div>
            </div>
          )}
          <div className="text-sm text-[#101010] w-full px-5">
            <div className="font-bold">{t("FName")}</div>
            <input
              type="text"
              placeholder={t("FNameTitle")}
              name="first_name"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.first_name}
              style={{
                borderColor:
                  formik?.touched?.first_name && formik?.errors?.first_name
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {formik?.touched?.first_name && formik?.errors?.first_name && (
                <div>{formik?.errors?.first_name}</div>
              )}
            </div>
            <div className="font-bold">{t("LName")}</div>
            <input
              type="text"
              placeholder={t("LNameTitle")}
              name="last_name"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
              id="last_name"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.last_name}
              style={{
                borderColor:
                  formik?.touched?.last_name && formik?.errors?.last_name
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {formik?.touched?.last_name && formik?.errors?.last_name && (
                <div>{formik?.errors?.last_name}</div>
              )}
            </div>
            <div className="font-bold">{t("Contact")}</div>
            <input
              type="text"
              placeholder={t("ContactTitle")}
              name="contact"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"

              id="contact"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.contact}
              style={{
                borderColor:
                  formik?.touched?.contact && formik?.errors?.contact
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {formik?.touched?.contact && formik?.errors?.contact && (
                <div>{formik?.errors?.contact}</div>
              )}
            </div>
            <div className="font-bold">{t("BrithDate")}</div>
            <input
              type="date"
              placeholder={t("BrithDateTitle")}
              name="birthdate"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"

              id="birthdate"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.birthdate}
              style={{


                borderColor:
                  formik?.touched?.birthdate && formik?.errors?.birthdate
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {formik?.touched?.birthdate && formik?.errors?.birthdate && (
                <div>{formik?.errors?.birthdate}</div>
              )}
            </div>
            <div className="font-bold">{t("Email")}</div>
            <input
              type="email"
              placeholder={t("EmailTitle")}
              name="email"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"

              id="email"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.email}
              style={{
                borderColor:
                  formik?.touched?.email && formik?.errors?.email
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {formik?.touched?.email && formik?.errors?.email && (
                <div>{formik?.errors?.email}</div>
              )}
            </div>
            <div className="font-bold mb-[8px]">{t("Department")}</div>
            <Select
              options={departments}
              value={formik.values.deptId}
              name="deptId"
              onChange={(option) => formik.setFieldValue("deptId", option)}
              onBlur={formik.handleBlur}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {/* {formik?.touched?.deptId && formik?.errors?.deptId && (
                <div>{formik?.errors?.deptId}</div>
              )} */}
            </div>
            <div className="font-bold mb-[8px]">{t("Position")}</div>
            <Select
              options={positions}
              value={formik.values.positionId}
              name="positionId"
              onChange={(option) => formik.setFieldValue("positionId", option)}
              onBlur={formik.handleBlur}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {/* {formik?.touched?.positionId && formik?.errors?.positionId && (
                <div>{formik?.errors?.positionId}</div>
              )} */}
            </div>
            <div className="font-bold">{t("Role")}</div>
            <Select
              options={roles}
              value={formik.values.roleId}
              name="roleId"
              onChange={(option) => formik.setFieldValue("roleId", option)}
              onBlur={formik.handleBlur}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {/* {formik?.touched?.roleId && formik?.errors?.roleId && (
                <div>{formik?.errors?.roleId}</div>
              )} */}
            </div>
          </div>
          <div className="w-[350px] mb-4 ">
            <button
              type="button"
              onClick={() => {
                setModalVisible(false);
                setIsDecline(true);
              }}
              className={`w-[140px]  rounded-[8px] border border-[#00A843] text-[#389365] hover:border-[#1F7549] hover:text-[#1F7549] ${isDecline ? "text-[#56B77B] border-[#56B77B]" : ""
                } text-[16px] font-[700] px-[24px] py-[8px] ml-[25px] `}
            >
              {t("Cancel")}
            </button>
            <button
              onClick={(e: any) => {
                formik.handleSubmit();
                setIsSubmit(true);
              }}
              type="submit"
              className={`w-[140px] rounded-[8px] bg-[#00A843] hover:bg-[#389365] text-[#F8FAF8] ${isSubmit ? "bg-[#7CCF93]" : ""
                }  text-[16px] mt-7 px-[24px] py-[8px] ml-[25px] `}
            >
              {t("SuBmit")}
            </button>
          </div>
        </div>
      </main>
    )
  );
};

export default Profile;
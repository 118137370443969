import React, { useState } from "react";
import moment from "moment";
import calender from "../../assets/calender.png";
import clock from "../../assets/clock.png";
import warning2 from "../../assets/warning2.png";
import requestBtn from "../../assets/requestBtn.png";
import stethoscope from "../../assets/stethoscope.png";
import location from "../../assets/location.png";
import request from "../../assets/request.png";
import reqMsg from "../../assets/reqMsg.png";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import { useTranslation } from "react-i18next";
import AnimatedBtn from "../animatedBtn";
import fetchWithToken from "../../utils/api";

interface SwapReq {
  id: number;
  coworker: string;
  fromdate: string;
  todate: string;
  currentstarttime: string;
  currentendtime: string;
  desiredstarttime: string;
  desiredendtime: string;
  department: number;
  location: number;
  req_message: string;
}

interface SwapRequests {
  id: number;
  schedule_from: string;
  startfrom: string;
  created_datetime: string;
  endfrom: string;
  shifttypefrom: string;
  profile_from: number;
  first_name_from: string;
  last_name_from: string;
  dept_from: string;
  position_from: string;
  code_name_from: string;
  location_from: string;
  schedule_to: string;
  startto: string;
  endto: string;
  shifttypeto: string;
  profile_to: number;
  first_name_to: string;
  last_name_to: string;
  dept_to: string;
  position_to: string;
  code_name_to: string;
  location_to: string;
  req_message: string;
}

interface SwapRequestProps {
  data?: SwapRequests | undefined;
  isModalVisible?: boolean | string | number;
  setModalVisible?: any;
  handleRefresh?: () => void;
  fetchSwapRequests?:any;
}

const SwapRequest: any = ({
  data,
  isModalVisible,
  setModalVisible,
  handleRefresh,
}) => {
  const [reqMsgm, setReqMsg] = useState<string>("");
  const [isReqMsgm, setIsReqMsg] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isDecline, setIsDecline] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const { t, i18n } = useTranslation();

  const handleAccept = async () => {
    setIsSubmit(true);
    setStatus("onclic");
    try {
      const response = await fetchWithToken(
        `/swapRequests/acceptswap/${data?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setStatus("success");
      if (response && handleRefresh) {
        handleRefresh();
      }
      setModalVisible(false);
      if (reqMsg) {
        const response = await fetchWithToken(
          `/swapRequests/enternotes/${data?.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ notes: reqMsg }),
          }
        );
      }
      // SuccessToast(
      //   "Accepted",
      //   `You accepted request from ${data?.first_name_from} ${data?.last_name_from}`
      // );
    } catch (error) {
      setStatus("fail");
      // FailToast("Accept swap failed. Please try again.");
      console.error(t("Add note failed:"), error);
    }
  };
  const handleDecline = async () => {
    setIsDecline(true);
    try {
      const response = await fetchWithToken(
        `/swapRequests/rejectswap/${data?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response && handleRefresh) {
        handleRefresh();
      }

      setModalVisible(false);
    } catch (error) {
      console.error (t("Decline swap failed :"), error);
    }
  };

  const formatTimeDifference = (createdDateTime: string) => {
    const createdMoment = moment(createdDateTime);
    const now = moment();
    let minutes = now.diff(createdMoment, "minutes");
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    return days >= 1
    ? t(`${days} days ago`) // Translates based on the current language
    : hours >= 1
    ? t(`${hours} hours ago`) // Translates based on the current language
    : minutes > 0
    ? t(`${minutes} minutes ago`) // Translates based on the current language
    : t("now"); // Translates based on the current language
  };

  return (
    isModalVisible && (
      <main
        className="fixed top-0 bottom-0 left-0 right-0
         bg-black bg-opacity-30 flex justify-center align-middle z-[1]"
        onClick={() => setModalVisible(!isModalVisible)}
      >
        <div
          className={`py-2 xl:w-[30%] w-[100%] ${isReqMsgm ? 
            "h-[62%]" : "h-[50%]"}  
            overflow-auto m-auto capitalize bg-[#FFF] 
            rounded-[8px] flex flex-col items-center scrollbar-hidden`}
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="py-3 text-center text-lg font-bold">{t("SwapRequest")}</div>
          <div className="text-sm text-[#101010] w-full">
            <div className="px-3 pt-2 flex pb-3 items-center">
              <img src={warning2} alt="!" className="w-6 h-6 p-1" />
              <div className="text-[#101010] font-bold text-lg flex-1">
                {data?.first_name_from} {data?.last_name_from}
                <span className="text-[#5D6561] text-sm ml-2 lowercase">
                {t("RequestASwap")}
                </span>
              </div>
              <span className="text-[#5D6561] text-sm font-semibold ml-2 lowercase">
                {data?.created_datetime
                  ? formatTimeDifference(data.created_datetime!)
                  : "Unknown Time"}
              </span>
            </div>
            <div className="px-1 flex justify-between mb-2 mx-[14px]">
              <div className="w-[170px] flex flex-col">
                <div className="text-[12px] text-[#A4ACA7]">{t("From")}</div>
                <div className="text-[12px] flex items-center">
                  <img alt="calender" src={calender} className="w-[13px]" />
                  <div className="text-[16px] ml-3">
                    {moment(data?.schedule_from).format("ddd, DD MMM YYYY")}
                  </div>
                </div>
              </div>
              <img src={requestBtn} alt="" className="w-5 h-5 mt-8 mr-4" />
              <div className="w-[170px] flex flex-col">
                <div className="text-[12px] text-[#A4ACA7]">{t("To")}</div>
                <div className="text-[12px] flex items-center">
                  <img alt="calender" src={calender} className="w-[13px]" />
                  <div className="text-[16px] ml-3">
                    {moment(data?.schedule_to).format("ddd, DD MMM YYYY")}
                  </div>
                </div>
              </div>
            </div>
            <div className="px-1 flex justify-between mx-[14px]">
              <div className="w-[170px] flex flex-col">
                <div className="text-[12px] flex items-center">
                  <img alt="clock" src={clock} className="w-[13px]" />
                  <div className="text-[16px] ml-3">
                    {moment(data?.startfrom, "HH:mm:ss").format("h:mm A")} -{" "}
                    {moment(data?.startto, "HH:mm:ss").format("h:mm A")}{" "}
                  </div>
                </div>
              </div>
              <div className="w-[170px] flex flex-col">
                <div className="text-[12px] flex items-center">
                  <img alt="clock" src={clock} className="w-[13px]" />
                  <div className="text-[16px] ml-3">
                    {moment(data?.endfrom, "HH:mm:ss").format("h:mm A")} -{" "}
                    {moment(data?.endto, "HH:mm:ss").format("h:mm A")}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="px-1 m-3 mx-[14px]">
              <div className="flex items-center mb-2">
                <img src={stethoscope} alt="" className="w-[13px] h-[13px]" />
                <div className="text-base text-[#333937] ml-3">
                  {data?.dept_to}
                </div>
              </div>
              <div className="flex items-center mb-2">
                <img src={location} alt="" className="w-[13px] h-[13px]" />
                <div className="text-base text-[#333937] ml-3">
                  {data?.location_to}
                </div>
              </div>
              <div className="flex items-center">
                <img src={request} alt="" className="w-[13px] h-[13px]" />
                <div className="text-base text-[#333937] ml-3">
                  {data?.req_message || `No Message`}
                </div>
              </div>
            </div>
            {isReqMsgm && (
              <>
                <div className="font-bold px-1 mx-[18px]">{t("Message2")}</div>
                <input
                  type="text"
                  placeholder={t("MessageLabel")}
                  name="req_message"
                  required
                  className="w-[420px] h-[50px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none px-3 mb-3 mx-[18px]"
                  id="req_message"
                  onChange={(e) => setReqMsg(e.target.value)}
                />
              </>
            )}
          </div>
          <div className="m-auto flex">
            <button
              type="button"
              onClick={handleDecline}
              className={`w-[174px] h-[40px] rounded-[8px] border border-[#00a843] text-[#00a843] hover:border-[#1F7549] hover:text-[#1F7549] ${
                isDecline ? "text-[#56B77B] border-[#56B77B]" : ""
              } text-[16px] font-[700] px-[14px] py-[8px] mr-2`}
            >
              {t("DeclineSwap")}
            </button>
            <div className="w-[174px] mr-3">
            <AnimatedBtn onClick={handleAccept} txt= {t("AcceptSwap")} status={status} setStatus={setStatus} />
            </div>
            {/* <button
              onClick={handleAccept}
              className={`w-[174px] h-[40px] rounded-[8px] bg-[#00A843] hover:bg-[#389365] text-[#F8FAF8] ${
                isSubmit ? "bg-[#7CCF93]" : ""
              } text-[16px] px-[24px] py-[8px] mr-2`}
            >
              Accept Swap
            </button> */}
            <button
              onClick={() => {
                setIsReqMsg(!isReqMsgm);
              }}
              type="submit"
              className={`w-[54px] h-[40px] rounded-[8px] bg-[#00A843] hover:bg-[#389365] text-[#F8FAF8] ${
                isSubmit ? "bg-[#1b201c]" : ""
              } text-[16px] px-[14px] pb-[14px]`}
            >
              <img src={reqMsg} alt="message" className="w-5 h-5 mt-2 ml-1" />
            </button>
          </div>
        </div>
      </main>
    )
  );
};

export default SwapRequest;
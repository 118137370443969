import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import external JSON files
import enTranslation from './i18n/en.json';
import arTranslation from './i18n/ar.json';

const resources = {
  en: {
    translation: enTranslation // Load English translations from en.json
  },
  ar: {
    translation: arTranslation // Load Arabic translations from ar.json
  }
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;



//   import i18n from "i18next";
// import { initReactI18next } from "react-i18next";

// // Import external JSON files
// import enTranslation from './i18n/en.json';
// import arTranslation from './i18n/ar.json';

// const resources = {
//   en: {
//     translation: enTranslation // Load English translations from en.json
//   },
//   ar: {
//     translation: arTranslation // Load Arabic translations from ar.json
//   }
// };

// i18n
//   .use(initReactI18next) // Passes i18n down to react-i18next
//   .init({
//     resources, 
//     lng: "en", // Default language
//     interpolation: {
//       escapeValue: false // React already protects from XSS
//     }
//   });

// export default i18n;

import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-dropdown/style.css";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";



interface roleProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
  fetchRoles: () => void;
}

const RoleModal: any= ({

  isModalVisible,
  setModalVisible,
  fetchRoles,
}) => {
  const isAdd = isModalVisible === true;
  const { t, i18n } = useTranslation();
  const formik = useFormik({
    initialValues: {
      role: "",
      code_name: "",
    },
    validationSchema: Yup?.object({
      role: Yup?.string()?.required("Required"),
      code_name: Yup?.string()?.required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        await fetchWithToken(isAdd ? "/roles" : `/roles/${isModalVisible}`, {
          method: isAdd ? "POST" : "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            role: values?.role,
            code_name: values?.code_name,
          }),
        });
        setModalVisible(!isModalVisible);
        fetchRoles();
      } catch (error) {
        console.error("Error creating role:", error);
      }
    },
  });

  const getRoleDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/roles/${id}`, {
        method: "GET",
      });
      formik?.setFieldValue("role", data?.role);
      formik?.setFieldValue("code_name", data?.code_name);
    } catch (error) {
      console.error("Failed to fetch role:", error);
    }
  };

  useEffect(() => {
    formik?.resetForm();
    if (
      typeof isModalVisible === "number" ||
      typeof isModalVisible === "string"
    )
      getRoleDetails(isModalVisible);
  }, [isModalVisible]);

  return (
    isModalVisible && (
      <main
        onClick={() => setModalVisible(!isModalVisible)}
        className="fixed top-0 bottom-0 left-0 right-0 
        bg-black bg-opacity-30 flex justify-center items-center"

      >
        <div className="container my-auto">
          <div
            onClick={(e) => e?.stopPropagation()}

            className="py-5 max-w-[90%] h-auto sm:max-w-[60%] sm:h-[70%] 
 overflow-auto m-auto w-full sm:w-[385px] capitalize 
 bg-[#FFF] rounded-[8px] flex flex-col items-center scrollbar-hidden"

          >
            <div className="text-center text-lg font-bold">{t("Role")}</div>
            <div className="text-sm text-[#101010]">
              <div className="font-bold">{t("Role")}</div>
              <input
                type="text"
                placeholder={t("RoleLabel")}
                name="role"
                required
                className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
                id="role"
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values?.role}
                style={{
                  borderColor:
                    formik?.touched?.role && formik?.errors?.role
                      ? "#E23121"
                      : "#5D6561",
                }}
              />
              <div className="font-bold">{t("CodeName")}</div>
              <input
                type="text"
                placeholder={t("CodeNameL")}
                name="code_name"
                required
                className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
                id="code_name"
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values?.code_name}
                style={{
                  borderColor:
                    formik?.touched?.code_name && formik?.errors?.code_name
                      ? "#E23121"
                      : "#5D6561",
                }}
              />
            </div>
            <div className="w-[350px]">
              <button
                type="button"
                onClick={() => setModalVisible(!isModalVisible)}
                className="w-[168px] h-[40px] rounded-[8px] border border-[#00a843] text-[#00a843] hover:border-[#E23121] hover:text-[#E23121] text-[16px] font-[700] px-[24px] py-[8px]"
              >
                {t("Cancel")}
              </button>
              <button
                type="submit"
                onClick={(e: any) => {
                  formik.handleSubmit();
                }}
                className="w-[168px] rounded-[8px] bg-[#56b77b] hover:bg-[#00A843] text-[#F8FAF8] p-2 text-[16px] mt-5 px-[24px] py-[8px] ml-[13px]"
              >
                {t("Confirm")}
              </button>
            </div>
          </div>
        </div>
      </main>
    )
  );
};

export default RoleModal;
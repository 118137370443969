import React, { useEffect, useState } from "react";
import calender from "../assets/calender.png";
import request from "../assets/request.png";
import requestBtn from "../assets/requestBtn.png";
import message from "../assets/message.png";
import redClose from "../assets/RedClose.png";
import greenCorrect from "../assets/greenCorrect.png";
import warn from "../assets/Warning3.png";
import moment from "moment";
import AddNoteModal from "./modals/AddNoteModal";
import SwapShift from "./modals/swapShift";
import SuccessToast from "./modals/successToast";
import FailToast from "./modals/failToast";
import Tooltip from "./tooltip";
import fetchWithToken from "../utils/api";
import { useTranslation } from "react-i18next";

interface Profile {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  contact: string;
  birthdate: string | null;
  profilePicture: string | null;
  emp_id: number;
  userId: number;
  deptId: number;
  positionId: number;
  roleId: number;
  createdAt: string;
  updatedAt: string;
}

interface Schedule {
  id: number;
  date: string;
  profileId: number;
  shiftId: number;
  deptId: number;
  createdAt: string;
  updatedAt: string;
  profile: Profile | null;
}

interface Swap {
  id: number;
  schedule_from: number;
  schedule_to: number;
  req_message: string;
  accepted: boolean;
  priority: number | null;
  notes: string | null;
  createdswap: boolean;
  createdAt: string;
  updatedAt: string;
  scheduleFrom: Schedule;
  scheduleTo: Schedule;
  status: string;
}

interface Option {
  name: string;
  value: string;
}

interface ScheduleTableProps {
  refresh: boolean;
  handleRefresh: () => void;
}

const Requests: React.FC<ScheduleTableProps> = ({ refresh, handleRefresh }) => {
  const [swapRequests, setSwapRequests] = useState<Swap[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<Option[]>([]);
  const [filterText, setFilterText] = useState("");
  const { t, i18n } = useTranslation();
  const [isAddNoteModalVisible, setAddNoteModalVisible] = useState(false);
  const [openSwapRequest, setOpenSwapRequest] = useState(false);
  const [selectedSwapRequestId, setSelectedSwapRequestId] = useState();
  const [isDecline, setIsDecline] = useState<boolean>(false);

  const fetchSwapRequests = async () => {
    try {
      const data = await fetchWithToken("/swaprequests", {
        method: "GET",
      });
      swapRequestsFilter(data);
    } catch (error) {
      console.error("Failed to fetch swap requests:", error);
    }
  };

  const swapRequestsFilter = (data: any) => {
    let arr = data;
    if (selectedDepartments?.length) {
      arr = arr?.filter((item: any) =>
        selectedDepartments?.some(
          (dept) =>
            item?.scheduleFrom?.deptId?.toString() ===
            dept?.value?.toString() ||
            item?.scheduleFrom?.deptId?.toString() === dept?.value?.toString()
        )
      );
    }
    setSwapRequests(arr);
  };

  useEffect(() => {
    fetchSwapRequests();
  }, [selectedDepartments, filterText, refresh]);

  const handleAccept = async (each: Swap) => {
    try {
      const response = await fetchWithToken(
        `/swapRequests/acceptswap/${each?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        handleRefresh();
      }
      // SuccessToast(
      //   "Accepted",
      //   `You accepted request from ${each?.scheduleTo?.profile?.first_name} ${each?.scheduleTo?.profile?.last_name}`
      // );
    } catch (error) {
      // FailToast("Accept swap failed. Please try again.");
      console.error(t("Accept swap failed :"), error);
    }
  };

  const handleDecline = async (each: Swap) => {
    setIsDecline(true);
    try {
      const response = await fetchWithToken(`/swapRequests/rejectswap/${each?.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response) {
        handleRefresh();
      }
      // SuccessToast(
      //   "Rejected",
      //   `You Rejected request from ${each?.scheduleTo?.profile?.first_name} ${each?.scheduleTo?.profile?.last_name}`
      // );
    } catch (error) {
      // FailToast("Decline swap failed. Please try again.");
      console.error(t("Decline swap failed :"), error);
    }
  };

  const handleShowNotes = (id: any) => {
    setAddNoteModalVisible(true);
    setSelectedSwapRequestId(id);
  };

  const formatTimeDifference = (createdDateTime: string) => {
    const createdMoment = moment(createdDateTime);
    const now = moment();
    let minutes = now.diff(createdMoment, "minutes");
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    return days >= 1
      ? t(`${days} days ago`) // Translates based on the current language
      : hours >= 1
        ? t(`${hours} hours ago`) // Translates based on the current language
        : minutes > 0
          ? t(`${minutes} minutes ago`) // Translates based on the current language
          : t("now"); // Translates based on the current language
  };

  const handleOpenNewSwapRequest = () => {
    setOpenSwapRequest(true);
  };

  const handleCloseNewSwapRequest = () => {
    setOpenSwapRequest(false);
  };

  return (
    <div className="max-w-[410px] min-w-[410px] max-h-full mt-[8px] mr-[8px]">
      <AddNoteModal
        id={selectedSwapRequestId}
        isModalVisible={isAddNoteModalVisible}
        setModalVisible={setAddNoteModalVisible}
      />
      <SwapShift
        isModalVisible={openSwapRequest}
        setModalVisible={handleCloseNewSwapRequest}
      />
      <div className="mb-[24px]">
        <button
          onClick={handleOpenNewSwapRequest}
          className="w-[174px] h-[40px] rounded-[8px] bg-[#00A843] hover:bg-[#389365] text-[#F8FAF8] text-[16px] px-[24px] py-[8px] mr-2"
        >
          {t("SwapRequestB")}
          <span className="ml-[16px]">+</span>
        </button>
      </div>
      <div className="text-[24px] font-[700]">{t("Swap Requests")}</div>
      <div className="overflow-y-scroll scrollbar-hidden h-[85%] flex flex-col xl:ml-0 ml-1">
        {Array.isArray(swapRequests) && swapRequests?.map((each) => (
          <div
            className="bg-[#f7f8f7] rounded-[8px] xl:w-full xl:max-w-full max-w-[82%] max-h-[250px] flex flex-col justify-between my-2 p-3 py-4"
            key={each?.id}
          >
            <div className="w-[100%] flex flex-row justify-between  items-center xl:gap-4 gap-2 mb-4">

              <div className="flex gap-2 align-middle justify-center ">
                <Tooltip
                  text={`${each?.scheduleTo?.profile?.first_name} ${each?.scheduleTo?.profile?.last_name} had a shift ${formatTimeDifference(each?.createdAt)}`}
                >
                  <img alt="warn" src={warn} className="w-[23px]" />
                </Tooltip>
                <div className="flex items-center align-baseline group">
                  {each?.scheduleTo?.profile ? (
                    <div className="xl:text-[18px] text-[13px] font-[700]">{`${each?.scheduleTo?.profile?.first_name} ${each?.scheduleTo?.profile?.last_name}`}</div>
                  ) : (
                    <div className="xl:text-[18px] text-[13px] font-[700]">
                      {t("No Profile Data")}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-row gap-1 xl:mr-0 ">
                <div className="xl:text-[14px] text-[12px] font-[400] ml-2 text-[#5d6561] ">
                  {!each?.createdswap
                    ? t("requested a day off")
                    : t("Requested a Shift Swap")
                  }
                </div>
                <div className="xl:text-[14px] text-[12px] font-[600]">
                  {formatTimeDifference(each?.createdAt)}
                </div>
              </div>

            </div>
            <div className="flex justify-between mb-3">
              <div className="h-[50px] w-[160px] flex flex-col justify-between">
                <div className="text-[12px] text-[#5d6561]">{t("From")}</div>
                <div className="text-[12px] flex items-center w-full">
                  <img alt="calender" src={calender} className="w-[13px]" />
                  <div className="xl:text-[16px] text-[12px] ml-3">
                    {moment(each?.scheduleFrom?.date).format(
                      "ddd, DD MMM YYYY"
                    )}
                  </div>
                </div>
              </div>
              <div className="w-[60px] h-[40px] text-[#00a843] text-[16px] font-[700] flex items-center justify-center">
                <img
                  alt="requestBtn"
                  src={requestBtn}
                  className="w-[16px] mt-5"
                />
              </div>
              <div className="h-[50px] w-[160px] flex flex-col justify-between">
                <div className="text-[12px] text-[#5d6561]">{t("To")}</div>
                <div className="text-[12px] flex items-center w-full">
                  <img alt="calender" src={calender} className="w-[13px]" />
                  <div className="xl:text-[16px] text-[12px] ml-3">
                    {moment(each?.scheduleTo?.date).format("ddd, DD MMM YYYY")}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center mb-3">
              <img alt="request" src={request} className="w-[14px]" />
              <div className="xl:text-[16px] text-[12px] ml-2 text-[#5d6561]">
                {each?.req_message || t("No Message")}
              </div>
            </div>
            <div className="w-full flex items-center justify-between mt-1">
              {each?.status === "rejected" && (
                <>
                  <div className="xl:w-[150px] w-[115px] h-[40px] rounded-[8px] border border-1 border-[#E23121] text-[#E23121] xl:text-[16px] text-[13px] font-[700] flex items-center justify-center cursor-not-allowed">
                    {t("Declined")}
                    <div className="flex items-baseline mt-1 ml-[16px] justify-between">
                      <img
                        alt="redClose"
                        src={redClose}
                        className="w-[16px]"
                      />
                    </div>
                  </div>
                  <div className="xl:w-[150px] w-[115px] h-[40px] rounded-[8px] bg-[#E8EBE9] text-[#C7CDC9] xl:text-[16px] text-[13px] font-[700] flex items-center justify-center cursor-not-allowed">
                    {t("Accept")}
                  </div>
                </>
              )}

              {each?.status === "accepted" && (
                <>
                  <div
                    // onClick={() => handleDecline(each?.id)}
                    className="xl:w-[150px] w-[115px] h-[40px] rounded-[8px]  bg-[#E8EBE9] text-[#C7CDC9] xl:text-[16px] text-[13px] font-[700] flex items-center justify-center cursor-not-allowed"
                  >
                    {t("Declined")}
                  </div>
                  <div
                    // onClick={() => handleAccept(each)}
                    className="xl:w-[150px] w-[115px] h-[40px] rounded-[8px] border border-1 border-[#00a843]  text-[#00a843] xl:text-[16px] text-[13px] font-[700] flex items-center justify-center cursor-not-allowed"
                  >
                    {t("Accepted")}
                    <div className="flex items-baseline mt-1 ml-[16px] justify-between">
                      <img
                        alt="greenCorrect"
                        src={greenCorrect}
                        className="w-[16px]"
                      />
                    </div>
                  </div>
                </>
              )}
              {each?.status === "pending" && (
                <>
                  <div
                    onClick={() => handleDecline(each)}
                    className="xl:w-[150px] w-[115px] h-[40px] rounded-[8px] border border-1 border-[#E23121] text-[#E23121]  text-[16px] font-[700] flex items-center justify-center cursor-pointer"
                  >
                    {t("Decline")}
                  </div>
                  <div
                    onClick={() => handleAccept(each)}
                    className="xl:w-[150px] w-[115px] h-[40px] rounded-[8px] border border-1 border-[#00a843]  text-[#00a843] text-[16px] font-[700] flex items-center justify-center cursor-pointer"
                  >
                    {t("Accept")}
                    <div className="flex items-baseline mt-1 ml-[16px] justify-between"></div>
                  </div>
                </>
              )}
              <div
                onClick={() => handleShowNotes(each?.id)}
                className="w-[60px] h-[40px] rounded-[8px] border border-1 border-[#00A843] text-[#389365] hover:border-[#1F7549] hover:text-[#1F7549] text-[16px] font-[700] flex items-center justify-center cursor-pointer"
              >
                <img alt="message" src={message} className="w-[16px]" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Requests;

import React, { useEffect, useState } from "react";
import whitePlus from "../../assets/whitePlus.png";
import downArrow from "../../assets/downArrow.png";
import search from "../../assets/search.png";
import schedules from "../../assets/cruds/schedule.png";
import department from "../../assets/cruds/department.png";
import location from "../../assets/cruds/location.png";
import position from "../../assets/cruds/position.png";
import profile from "../../assets/cruds/profile.png";
import role from "../../assets/cruds/role.png";
import shift from "../../assets/cruds/shift.png";
import swap from "../../assets/cruds/swap.png";
import users from "../../assets/cruds/users.png";
import activeSchedule from "../../assets/cruds/activeSchedule.png";
import activeDepartment from "../../assets/cruds/activeDepartment.png";
import activeLocation from "../../assets/cruds/activeLocation.png";
import activePosition from "../../assets/cruds/activePosition.png";
import activeProfile from "../../assets/cruds/activeProfile.png";
import activeRole from "../../assets/cruds/activeRole.png";
import activeShift from "../../assets/cruds/activeShift.png";
import activeSwap from "../../assets/cruds/activeSwap.png";
import activeUsers from "../../assets/cruds/activeUsers.png";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../components/catalyst/dropdown"
import Schedules from "../../components/tabs/schedules";
import Profiles from "../../components/tabs/profiles";
import Departments from "../../components/tabs/departments";
import Positions from "../../components/tabs/positions";
import Roles from "../../components/tabs/roles";
import Shifts from "../../components/tabs/shifts";
import SwapRequests from "../../components/SwapRequests";
import Locations from "../../components/tabs/locations";
import Users from "../../components/tabs/users";
import Leaves from "../../components/tabs/leaves";
import { useTranslation } from "react-i18next";
import { WeekPicker } from "../../components/weekpicker";
import fetchWithToken from "../../utils/api";
import { Select } from "@headlessui/react";

const Cruds = () => {
  const { t, i18n } = useTranslation();
  const tabs = [
    {
      name: t("title"),
      img: schedules,
      active: activeSchedule,
      component: (props: any) => <Schedules {...props} />,
    },
    {
      name: t("ProfileT"),
      img: profile,
      active: activeProfile,
      component: (props: any) => <Profiles {...props} />,
    },
    {
      name: t("Departments"),
      img: department,
      active: activeDepartment,
      component: (props: any) => <Departments {...props} />,
    },
    {
      name: t("Positions"),
      img: position,
      active: activePosition,
      component: (props: any) => <Positions {...props} />,
    },
    {
      name: t("Roles"),
      img: role,
      active: activeRole,
      component: (props: any) => <Roles {...props} />,
    },
    {
      name: t("Shifts"),
      img: shift,
      active: activeShift,
      component: (props: any) => <Shifts {...props} />,
    },
    {
      name: t("SwapRequests"),
      img: swap,
      active: activeSwap,
      component: (props: any) => <SwapRequests {...props} />,
    },
    {
      name: t("Locations"),
      img: location,
      active: activeLocation,
      component: (props: any) => <Locations {...props} />,
    },
    {
      name: t("User"),
      img: users,
      active: activeUsers,
      component: (props: any) => <Users {...props} />,
    },
    {
      name: t("Leaves"),
      img: users,
      active: activeUsers,
      component: (props: any) => <Leaves {...props} />,
    },
  ];

  const [week, setWeek] = useState<any>();
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [myusers, setMyUsers] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState();

  const fetchUsers = async () => {
    try {
      const data = await fetchWithToken("/users", {
        method: "GET",
      });
      setMyUsers(data?.data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (myusers) {
      setSelectedUser(myusers[0]?.id)
    }
  }, [myusers])

  return (
    <div className="flex flex-1 w-full h-full flex-col">
      <div className="w-full flex items-center justify-between xl:flex-row flex-col gap-4">
        <div className="xl:h-[40px] h-auto flex flex-row">
          <Dropdown>
            <DropdownButton
              style={{
                border: 0,
                padding: 0,
                width: "200px",
              }}
            >
              <div className="h-[40px] w-full  relative py-2 px-4 bg-[#F7F8F7] flex hover:bg-[#E8EBE9]">
                <img
                  src={selectedTab.active}
                  alt=""
                  className="w-4 h-4 mr-2 absolute top-3"
                />
                <div className="text-base font-semibold text-[#101010] absolute left-10">
                  {selectedTab.name}
                </div>
                <img
                  src={downArrow}
                  alt=""
                  className="w-3 h-2 absolute right-4 top-4"
                />
              </div>
            </DropdownButton>
            <DropdownMenu
              className="bg-[white] cursor-pointer"
              style={{
                backgroundColor: "white",
              }}
            >
              {Array.isArray(tabs) && tabs.map((tab, index) => {
                return (
                  <DropdownItem
                    className="cursor-pointer"
                    key={index}
                    onClick={() => {
                      setSelectedTab(tab);
                    }}
                  >
                    <img
                      src={selectedTab.name === tab.name ? tab.active : tab.img}
                      alt=""
                      className={`w-4 h-4 mr-2 my-auto`}
                    />
                    <div className="text-base font-semibold text-[#101010] left-10">
                      {tab.name}
                    </div>
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
          <div className="h-full xl:w-[320px] w-[50%] bg-[#FDFDFF] rounded-lg border border-[#7E8581] ml-4 px-4 py-2 flex justify-between">
            <input
              type="text"
              placeholder={t("Search")}
              className="outline-none xl:w-[250px] w-[45%] text-sm font-normal leading-[22px] bg-[#FDFDFF]"
            />
            <img src={search} alt="search" className="w-5 h-5" />
          </div>
        </div>
        <div className="xl:mt-0 mt-1 flex">
          <Select
            className="w-[150px] h-[40px] my-2 border border-[#7E8581] rounded-md mr-3"
            name="deptId"
            onChange={(e: any) => setSelectedUser(e.target.value)}
          >
            {
              myusers.map((user) => (
                <option key={user?.id} value={user?.id}>{user?.first_name}{" "}{user?.last_name}</option>
              ))
            }
          </Select>
          <WeekPicker onChange={(e: any) => setWeek(e)} />
        </div>
        <button
          type="button"
          onClick={() => setModalVisible(!isModalVisible)}
          className="bg-[#00a843] hover:bg-[#389365] w-[180px] h-[40px] rounded-[8px] text-[16px] font-[700] flex items-center justify-center text-[#fff]"
        >
          {t("Add", { name: selectedTab?.name?.slice(0, -1) })}


          <img src={whitePlus} alt="+" className="w-3 h-3 ml-3" />
        </button>
      </div>
      <div className="flex-1 overflow-y-auto scrollbar-hidden mt-1">
        {selectedTab?.component({ isModalVisible, setModalVisible, week, selectedUser, user: `sa` })}
      </div>
    </div>
  );
};

export default Cruds;

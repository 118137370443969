import { ToastOptions, toast } from "react-toastify";

const FailToast = (message: string | undefined, t: (key: string) => string) => {
  const toastProps: ToastOptions = {
    position: "bottom-right",
    className: "foo-bar",
    icon: (
      <img
        alt="failToast"
        src={require("../../assets/RedClose.png")}
        className="w-[40px] min-w-[40px] max-w-[40px]"
      />
    ),
  };

  toast.error(
    <div className="msg-container ml-5">
      <p className="msg-title font-[700] text-[18px] text-[black]">{t("Failed")}</p>
      <p className="msg-description text-[12px] text-[#5d6561] capitalize">{message}</p>
    </div>,
    toastProps
  );
};

export default FailToast;

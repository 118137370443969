import React, { useEffect, useState } from "react";
import moment from "moment";
import calender from "../../assets/calender.png";
import clock from "../../assets/clock.png";
import stethoscope from "../../assets/stethoscope.png";
import location from "../../assets/location.png";
import editPen from "../../assets/editPen.png";
import ScheduledshiftDetails from "../modals/scheduleShiftDetails";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { useSelector } from "react-redux";

interface Shift {
  shift: any;
  date: string;
  start_time: string;
  end_time: string;
  shift_type: string | null;
  department: string;
  location: string;
  city: string;
}


const Schedule = () => {
  const { t, i18n } = useTranslation();
  const userData = useSelector((state: any) => state.user.user);
  const [schedule, setSchedule] = useState<Shift[]>([]);

  const [selectedSchedule, setSelectedSchedule] = useState<
    any
  >(undefined);
  const [isModalVisible, setModalVisible] = useState(false);
  const fetchschedules = async () => {
    try {
      const data = await fetchWithToken("/schedules/upcoming", {
        method: "GET",
      });
      setSchedule(data?.data);
    } catch (error) {
      console.error("Failed to fetch swap requests:", error);
    }
  };

  useEffect(() => {
    fetchschedules();
  }, []);

  const handlePressEdit = (data: any) => {
    setModalVisible(true);
    setSelectedSchedule(data);
  };

  if (!schedule?.length) return null;

  return (
    <div className="h-full">
      <ScheduledshiftDetails
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        data={
          selectedSchedule || {
            date: "",
            start_time: "",
            end_time: "",
            shift_type: null,
            department: "",
            location: "",
          }
        }
      />
      <div className="flex items-center h-[40px] mb-[16px]">
        <div className="text-[24px] font-[700] mr-[10%]">{t("Schedule")}</div>
      </div>
      <div
        //   style={{ border: "2px solid blue" }}
        className="flex items-center justify-between lg:flex-row flex-col xl:gap-0 gap-5"
      >
        {Array.isArray(schedule) && schedule?.map(
          (item, index) =>
            index < 2 && (
              <div key={index} className="lg:min-w-[416px] min-w-[100%] w-[49%] mr-2 relative">
                <div
                  // style={{ border: "2px solid black" }}
                  className="bg-[#f7f8f7] w-[200px] rounded-t-[8px] flex items-center justify-center h-[40px] text-[18px] font-[700]"
                >
                 {index === 0 ? t("NextShift") : t("UpcomingShift")}

                </div>
                <div className="flex flex-col justify-around py-4 pl-5 bg-[#f7f8f7] w-full rounded-[8px] rounded-tl-none relative h-[180px]">
                  <div className="text-[12px] flex items-center justify-between pr-4 w-full">
                    <div className="flex">
                      <img
                        alt="calender"
                        src={calender}
                        className="w-[20px] h-[20px]"
                      />
                      <div className="text-[16px] ml-3">
                        {moment(item?.date)?.format("ddd, DD MMM YYYY")}
                      </div>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        handlePressEdit(item);
                      }}
                    >
                      <img
                        alt="editPen"
                        src={editPen}
                        className="w-[20px] h-[20px]"
                      />
                    </div>
                  </div>
                  <div className="text-[12px] flex items-center w-full">
                    <img
                      alt="calender"
                      src={clock}
                      className="w-[20px] h-[20px]"
                    />
                    <div className="text-[16px] ml-3">
                      {moment(item?.shift?.start_time, "HH:mm:ss")?.format("h:mm A")}
                    </div>
                  </div>
                  <div className="text-[12px] flex items-center w-full">
                    <img
                      alt="calender"
                      src={stethoscope}
                      className="w-[20px] h-[20px]"
                    />
                    <div className="text-[16px] ml-3">{item?.department || "No Department"}</div>
                  </div>
                  <div className="text-[12px] flex items-center w-full">
                    <img
                      alt="calender"
                      src={location}
                      className="w-[20px] h-[20px]"
                    />
                    <div className="text-[16px] ml-3 text=[#5d6561]">
                      {item?.location || "No Location"}
                    </div>
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};
export default Schedule;

import React, { memo, useEffect, useState } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import DeleteModal from "../modals/deleteModal";
import ScheduleModal from "../modals/schedule";
import editIcon from "../../assets/editIcon.png";
import deleteIcon from "../../assets/deleteIcon.png";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";

interface Location {
  id: number;
  hospital_name: string;
  addr_one: string;
  addr_two: string;
  city: string;
  state: string;
  country: string;
  postal_code: number;
  createdAt: string;
  updatedAt: string;
}

interface Department {
  id: number;
  dept_name: string;
  locationId: number;
  createdAt: string;
  updatedAt: string;
  location: Location;
}

interface Position {
  id: number;
  position_name: string;
  createdAt: string;
  updatedAt: string;
}

interface Role {
  id: number;
  role: string;
  code_name: string;
  createdAt: string;
  updatedAt: string;
}

interface Shift {
  id: number;
  start_time: string;
  end_time: string;
  shift_type: string;
}

interface Schedule_dept {
  city: string;
  dept_name: string;
  deptid: number;
  hospitalname: string;
  state: string;
}

interface Schedule {
  id: number;
  date: string;
  deptId: number;
  shift: Shift;
  schedule_dept: Schedule_dept;
}

interface Schedule {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  contact: string;
  birthdate: string | null;
  profilePicture: string | null;
  userId: number;
  deptId: number;
  positionId: number;
  roleId: number;
  createdAt: string;
  updatedAt: string;
  department: Department;
  position: Position;
  role: Role;
  schedules: Schedule[];
}

interface scheduleProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
  week: any
  selectedUser: any;
  mySelectedUser: any;
}

interface User {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  department_id: number;
  position_id: number;
  role_id: number;
  contact: string | null;
  birthdate: string | null;
  profile_picture: string | null;
  resetpasswordtoken: string | null;
  resetpasswordexpires: string | null;
  createdAt: string;
  updatedAt: string;
}

const Schedules: React.FC<scheduleProps> = ({
  isModalVisible,
  setModalVisible, week, selectedUser, mySelectedUser
}) => {
  const [content, setContent] = useState<string>("");
  const { t, i18n } = useTranslation();
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [deleteScheduleModal, setDeleteScheduleModal] = useState<
    boolean | number | string
  >(false);
  const fetchSchedules = async () => {
    const firstDayFormatted = moment(week?.firstDay).format("YYYY-MM-DD");
    const lastDayFormatted = moment(week?.lastDay).format("YYYY-MM-DD");
    try {
      const data = await fetchWithToken(`/schedules?startDate=${firstDayFormatted}&endDate=${lastDayFormatted}&user_id=${selectedUser}`, {
        method: "GET",
      });
      setSchedules(data?.data);
    } catch (error) {
      console.error("Failed to fetch schedules:", error);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [week, selectedUser]);


  return (
    <>
      <Table className="relative mt-5">
        <TableHead className="hidden lg:table-header-group">
          <TableRow className="bg-[#F7F8F7]">
            <TableHeader>{t("Date")}</TableHeader>
            <TableHeader>{t("Shift")}</TableHeader>
            <TableHeader>{t("Department")}</TableHeader>
            <TableHeader></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedules?.length &&
            Array.isArray(schedules) &&
            schedules?.map((each) => {
              return (
                <TableRow
                  key={each?.id}
                  className="lg:table-row flex flex-col lg:flex-row mb-4 border lg:border-none"
                >

                  <TableCell className="lg:hidden font-bold bg-gray-100">{t("Date")}</TableCell>
                  <TableCell>{each?.date}</TableCell>

                  <TableCell className="lg:hidden font-bold bg-gray-100">{t("Shift")}</TableCell>
                  <TableCell>
                    {`${moment(
                      each?.shift?.start_time,
                      "HH:mm:ss.SSS"
                    ).format("hh:mm:ss A")} - ${moment(
                      each?.shift?.end_time,
                      "HH:mm:ss.SSS"
                    ).format("hh:mm:ss A")}`}
                  </TableCell>

                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("Department")}
                  </TableCell>
                  <TableCell>{each?.schedule_dept?.dept_name ? each?.schedule_dept?.dept_name : "No Department"}</TableCell>

                  <TableCell className="flex justify-end lg:w-[120px]">
                    <div
                      onClick={() => {
                        setModalVisible(each?.id);
                      }}
                      className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                    >
                      <img
                        alt="editIcon"
                        src={editIcon}
                        className="w-6 h-6"
                      />
                    </div>
                    <div
                      onClick={() => {
                        setDeleteScheduleModal(each?.id);
                        setContent(each?.date);
                      }}
                      className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                    >
                      <img
                        alt="deleteIcon"
                        src={deleteIcon}
                        className="w-6 h-6"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            }
            )}
        </TableBody>
      </Table>
      <ScheduleModal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        fetchSchedules={fetchSchedules}
      />
      <DeleteModal
        route="schedules"
        content={content}
        visibilityState={deleteScheduleModal}
        setState={setDeleteScheduleModal}
        fetchAllCall={fetchSchedules}
      />
    </>
  );
};

export default memo(Schedules);

import React, { useEffect, useState } from "react";
import moment from "moment";
import close from "../../assets/close.png";
import add from "../../assets/add.png";
import noProfile from "../../assets/noProfile.png";
import SingleChat from "./singleChat";
import { useTranslation } from 'react-i18next';
import fetchWithToken from "../../utils/api";

interface chatProps {
  showChats: boolean;
  setShowChats: React.Dispatch<React.SetStateAction<boolean>>;
}

const Chats: React.FC<chatProps> = ({ showChats, setShowChats }) => {
  const [openChat, setOpenChat] = useState<boolean>(false);
  const [chats, setChats] = useState<any[]>([]); // Specify type for chats
  const { t, i18n } = useTranslation();

  const fetchChats = async () => {
    try {
      const data = await fetchWithToken("/notifications", {
        method: "GET",
      });
      setChats(data);
    } catch (error) {
      //console.error("Failed to fetch chats:", error);
      console.error(t("Failed to fetch chats:"), error);
    }
  };

  useEffect(() => {
    fetchChats();
  }, []);

  const formatTimeDifference = (createdDateTime: string) => {
    const createdMoment = moment(createdDateTime);
    const now = moment();
    let minutes = now.diff(createdMoment, "minutes");
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    return days >= 1
    ? t(`${days} days ago`) // Translates based on the current language
    : hours >= 1
    ? t(`${hours} hours ago`) // Translates based on the current language
    : minutes > 0
    ? t(`${minutes} minutes ago`) // Translates based on the current language
    : t("now"); // Translates based on the current language
  };

  return (
    <>
      {showChats ? (
        <main className="w-full md:w-[28vw] h-[85vh] fixed top-[12vh] right-0 md:right-[1vw] z-40 bg-white rounded-lg overflow-y-scroll scrollbar-hidden">
          <SingleChat showChat={openChat} setShowChat={setOpenChat} />
          <div className="relative mx-3">
            <div className="my-5 text-[#242222] text-2xl font-bold text-center relative">
              <div>{t("Chat")}</div>
              <img
                src={close}
                alt="X"
                onClick={() => setShowChats(false)}
                className="w-4 h-4 mt-3 absolute right-0 top-0"
              />
            </div>
            <div className="w-[35%] h-[10vh] mx-auto my-7 flex justify-between">
              <div className="w-[60px] h-full">
                <img
                  src={add}
                  alt="new chat"
                  className="w-[40px] h-[40px] rounded mx-auto bg-[#F8FAF8]"
                />
                <div className="text-xs font-semibold leading-[18px] my-1">
                  {t("New Chat")}
                </div>
              </div>
              <div className="w-[60px] h-full">
                <img
                  src={add}
                  alt="new group"
                  className="w-[40px] h-[40px] rounded mx-auto bg-[#F8FAF8]"
                />
                <div className="text-xs font-semibold leading-[18px] my-1">
                  {t("New Group")}
                </div>
              </div>
            </div>
            <div className="my-7">
              {Array.isArray(chats) && chats.map((chat, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setOpenChat(true);
                  }}
                  className="w-full h-[50px] mb-4 relative inline-flex"
                >
                  <img
                    src={noProfile}
                    alt=""
                    className="h-[50px] w-[50px] rounded-full"
                  />
                  <div className="w-full flex justify-between ml-3">
                    <div className="text-base font-semibold leading-6 text-[#101010]">
                      {chat.senderName} {/* Adjust based on chat structure */}
                    </div>
                    <div className="text-sm text-[#5d6561]">
                      {formatTimeDifference(chat.createdAt)} {/* Adjust based on chat structure */}
                    </div>
                  </div>
                  <div className="text-base font-normal absolute left-[62px] top-7 text-[#5D6561] leading-6">
                    {chat.message} {/* Adjust based on chat structure */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      ) : null}
    </>
  );
};

export default Chats;

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-dropdown/style.css";
import Select, { SingleValue } from "react-select";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";

interface Location {
  id: number;
  hospital_name: string;
  addr_one: string;
  addr_two: string;
  city: string;
  state: string;
  country: string;
  postal_code: number;
  createdAt: string;
  updatedAt: string;
  value: number;
  label: string;
}

interface Department {
  id: number;
  dept_name: string;
  locationId: number;
  createdAt: string;
  updatedAt: string;
  location: Location;
  value: number;
  label: string;
}

interface departmentProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
  fetchDepartments: () => void;
}

const DepartmentModal: React.FC<departmentProps> = ({
  isModalVisible,
  setModalVisible,
  fetchDepartments,
}) => {
  const isAdd = isModalVisible === true;
  const [locations, setLocations] = useState<Location[]>([]);
  
  const [departments, setDepartments] = useState<Department[]>([]);

  const formik = useFormik<{
    dept_name: string;
    locationId: SingleValue<{ value: number; label: string }> | null;
  }>({
    initialValues: {
      dept_name: "",
      locationId: null,
    },
    validationSchema: Yup?.object({
      dept_name: Yup.string()?.required("qwertuy"),
      locationId: Yup.object({
        value: Yup.string().required("Value is required"),
      }),
    }),
    onSubmit: async (values) => {
      try {
        await fetchWithToken(
          isAdd ? "/departments" : `/departments/${isModalVisible}`,
          {
            method: isAdd ? "POST" : "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...values,
              dept_name: values?.dept_name,
              locationId: values?.locationId?.value,
            }),
          }
        );
        fetchDepartments();
        setModalVisible(!isModalVisible);
      } catch (error) {
        //console.error("Error creating department:", error);
         console.error(t("Error creating department:"), error);
      }
    },
  });

  const getDepartmentDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/departments/${id}`, {
        method: "GET",
      });

      formik?.setFieldValue("dept_name", data?.dept_name);
      formik?.setFieldValue(
        "locationId",
        locations?.filter((each) => each?.id === data?.locationId)[0]
      );
    } catch (error) {
      console.error("Failed to fetch department:", error);
    }
  };

  const fetchAllDepartments = async () => {
    try {
      const data = await fetchWithToken("/departments", {
        method: "GET",
      });

      setDepartments(
        data?.map((each: Department) => ({
          ...each,
          value: each?.id,
          label: each?.dept_name,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch departments:", error);
    }
  };

  const fetchLocations = async () => {
    try {
      const data = await fetchWithToken("/locations", {
        method: "GET",
      });

      setLocations(
        data?.map((each: Location) => ({
          ...each,
          value: each?.id,
          label: each?.hospital_name,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch locations:", error);
    }
  };

  useEffect(() => {
    formik?.resetForm();
    if (
      typeof isModalVisible === "number" ||
      typeof isModalVisible === "string"
    )
      getDepartmentDetails(isModalVisible);
  }, [isModalVisible]);

  useEffect(() => {
    fetchAllDepartments();
    fetchLocations();
  }, []);
  
  const { t } = useTranslation();

  // Return null when the modal is not visible
  if (!isModalVisible) return null;

  return (
    <main
      onClick={() => setModalVisible(!isModalVisible)}
      className="fixed top-0 bottom-0 left-0 right-0 
      bg-black bg-opacity-30 flex justify-center items-center"
    >
      <div className="container my-auto">
        <div
          onClick={(e) => e?.stopPropagation()}
          className="py-5 max-w-[90%] h-auto sm:max-w-[60%] sm:h-[70%] 
          overflow-auto m-auto w-full sm:w-[385px] capitalize 
          bg-[#FFF] rounded-[8px] flex flex-col items-center scrollbar-hidden"
        >
          <div className="text-center text-lg font-bold">
            {t("Department")}
          </div>
          <div className="text-sm text-[#101010]">
            <div className="font-bold">{t("Department")}</div>
            <input
              type="text"
              placeholder={t("DepartmentLabel")}
              name="dept_name"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.dept_name}
              style={{
                borderColor:
                  formik?.touched?.dept_name && formik?.errors?.dept_name
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.dept_name && formik?.errors?.dept_name && (
                <div>{formik?.errors?.dept_name}</div>
              )}
            </div>
            <div className="font-bold">{t("Hospital")}</div>
            <Select
              options={locations}
              value={formik.values.locationId || t("HospitalLabel")}
              name="locationId"
              onChange={(option) =>
                formik.setFieldValue("locationId", option)
              }
              onBlur={formik.handleBlur}
              className="w-[350px] h-[40px] my-2"
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.locationId && formik?.errors?.locationId && (
                <div>{formik?.errors?.locationId}</div>
              )}
            </div>
          </div>
          <div className="w-[350px]">
            <button
              type="button"
              onClick={() => setModalVisible(!isModalVisible)}
              className="w-[168px] h-[40px] rounded-[8px] border border-[#00a843] text-[#00a843] hover:border-[#E23121] hover:text-[#E23121] text-[16px] font-[700] px-[24px] py-[8px]"
            >
              {t("Cancel")}
            </button>
            <button
              type="submit"
              onClick={(e: any) => {
                formik.handleSubmit();
              }}
              className="w-[168px] rounded-[8px] bg-[#56b77b] hover:bg-[#00A843] text-[#F8FAF8] p-2 text-[16px] mt-5 px-[24px] py-[8px] ml-[13px]"
            >
              {t("Confirm")}
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DepartmentModal;

import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";

interface LeaveProps {
  isModalVisible: any;
  setIsModalVisible: any;
  fetchLeaves:any
}

const Leave: React.FC<LeaveProps> = ({ isModalVisible, setIsModalVisible,fetchLeaves }) => {
  const { t, i18n } = useTranslation(); // Move this line up here
  const formik = useFormik({
    initialValues: {
      start_date: "",
      end_date: "",
      leave_type: "",
    },
    
    validationSchema: Yup?.object({
      start_date: Yup?.string()?.required(t("Required")),
      end_date: Yup?.string()?.required(t("Required")),
      leave_type: Yup?.string()?.required(t("Required")),
    }),

    onSubmit: async (values) => {
      try {
        await fetchWithToken(`/leave`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            start_date: moment(values?.start_date).format("DD-MM-YYYY"),
            end_date: moment(values?.end_date).format("DD-MM-YYYY"),
            leave_type: values?.leave_type,
          }),
        });
        setIsModalVisible(!isModalVisible);
      } catch (error) {
        //console.error("Error creating leave:", error);
        console.error(t("Error creating leave:"), error);
      }
    },
  });

  useEffect(() => {
    formik?.resetForm();
  }, [isModalVisible]);
  return (
    isModalVisible && (
      <main
        className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center align-middle z-[999]"
        onClick={() => setIsModalVisible(!isModalVisible)}
      >
        <div
          className="py-5 xl:max-w-[40%] overflow-auto m-auto xl:w-[385px] w-[98%] capitalize bg-[#FFF] rounded-[8px] flex flex-col items-center justify-center  scrollbar-hidden"
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="text-center text-lg font-bold">{t("Mesaage")}</div>
          <div className="text-sm text-[#101010] w-full px-5">
            <div className="font-bold">{t("StartDate")}</div>
            <input
              type="date"
              placeholder={t("StartDateTitle")}
              name="start_date"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="start_date"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.start_date}
              style={{
                borderColor:
                  formik?.touched?.start_date && formik?.errors?.start_date
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.start_date && formik?.errors?.start_date && (
                <div>{formik?.errors?.start_date}</div>
              )}
            </div>
            <div className="font-bold">{t("EndDate")}</div>
            <input
              type="date"
              placeholder={t("EndDateTitle")}
              name="end_date"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="end_date"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.end_date}
              style={{
                borderColor:
                  formik?.touched?.end_date && formik?.errors?.end_date
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.end_date && formik?.errors?.end_date && (
                <div>{formik?.errors?.end_date}</div>
              )}
            </div>
            <div className="font-bold">{t("LeaveType")}</div>
            <input
              type="text"
              placeholder={t("LeaveTypeTitle")}
              name="leave_type"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="leave_type"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.leave_type}
              style={{
                borderColor:
                  formik?.touched?.leave_type && formik?.errors?.leave_type
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.leave_type && formik?.errors?.leave_type && (
                <div>{formik?.errors?.leave_type}</div>
              )}
            </div>
          </div>
          <div className="w-[350px]">
            <button
              type="button"
              onClick={() => setIsModalVisible(false)}
              className="w-[168px] h-[40px] rounded-[8px] border border-[#00a843] text-[#00a843] hover:border-[#E23121] hover:text-[#E23121] text-[16px] font-[700] px-[24px] py-[8px]"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={(e: any) => {
                formik.handleSubmit();
              }}
              type="submit"
              className="w-[168px] rounded-[8px] bg-[#56b77b] hover:bg-[#00A843] text-[#F8FAF8] p-2 text-[16px] mt-5 px-[24px] py-[8px] ml-[13px]"
            >
              {t("Submit")}
            </button>
          </div>
        </div>
      </main>
    )
  );
};

export default Leave;

import React, { useEffect, useState } from "react";
import moment from "moment";
import Tooltip from "./tooltip";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../utils/api";
import SwapRequest from "./modals/swapRequest";

// @ts-ignore
// import TimeAgo from "react-timeago";

interface SwapRequests {
  id: number;
  schedule_from: string;
  startfrom: string;
  created_datetime: string;
  endfrom: string;
  shifttypefrom: string;
  profile_from: number;
  first_name_from: string;
  last_name_from: string;
  dept_from: string;
  position_from: string;
  code_name_from: string;
  location_from: string;
  schedule_to: string;
  startto: string;
  endto: string;
  shifttypeto: string;
  profile_to: number;
  first_name_to: string;
  last_name_to: string;
  dept_to: string;
  position_to: string;
  code_name_to: string;
  location_to: string;
  req_message: string;
}

const SwapRequests = () => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [swapRequests, setSwapRequests] = useState<SwapRequests[]>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const [selectedSwapRequest, setSelectedSwapRequest] =
    useState<SwapRequests>();
  const [refresh, setRefresh] = useState(false);
  const handleRefresh = () => {
    setRefresh((prev) => !prev);
  };

  const fetchSwapRequests = async () => {
    try {
      const data = await fetchWithToken("/dashboard/displayswapreq", {
        method: "POST",
      });
      setSwapRequests(data);
    } catch (error) {
      console.error("Failed to fetch swap requests:", error);
    }
  };

  useEffect(() => {
    fetchSwapRequests();
  }, [refresh]);

  const formatTimeDifference = (createdDateTime: string) => {
    const createdMoment = moment(createdDateTime);
    const now = moment();
    let minutes = now.diff(createdMoment, "minutes");
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    return days >= 1
    ? t(`${days} days ago`) // Translates based on the current language
    : hours >= 1
    ? t(`${hours} hours ago`) // Translates based on the current language
    : minutes > 0
    ? t(`${minutes} minutes ago`) // Translates based on the current language
    : t("now"); // Translates based on the current language
  };

  return (
    <>
      <div className="text-[24px] font-[700] mt-[24px] mb-[16px]">
        {t("SwapRequest")}
      </div>
      <SwapRequest
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        data={selectedSwapRequest}
        handleRefresh={handleRefresh}
      />

      {Array.isArray(swapRequests) && swapRequests?.map((item, index) => (
        <div
          className="bg-[#f7f8f7] rounded-[8px] w-full max-h-[250px] flex flex-col justify-between my-2 p-3 py-4"
          key={index}
        >
          <div className="w-full flex items-center justify-between mb-4">
            <div className="flex items-center">
              <div className="text-[18px] font-[700] flex items-center">
                <Tooltip text={`${item?.first_name_from} ${item?.last_name_from} had a shift ${formatTimeDifference(item.created_datetime)}`}>
                  <img
                    alt="warning2"
                    src={require("../assets/warning2.png")}
                    className="w-[20px] mr-2"
                  />
                </Tooltip>
                <img alt="spark" src={require("../assets/spark.png")} className="w-[15px] mr-2" />
                {item?.first_name_from} {item?.last_name_from}
              </div>
              <div className="text-[14px] font-[400] ml-2 text-[#5d6561]">
               {t("RequestASwap")}
              </div>
            </div>
            <div className="text-[14px] font-[600]">
              {formatTimeDifference(item.created_datetime)}
            </div>
          </div>
          <div className="flex justify-between mb-3">
            <div className="h-[50px] w-[220px] flex flex-col justify-between">
              <div className="text-[12px] text-[#5d6561]"> {t("From")}</div>
              <div className="text-[12px] flex items-center w-full">
                <img
                  alt="calender"
                  src={require("../assets/calender.png")}
                  className="w-[16px] h-[16px]"
                />
                <div className="text-[16px] ml-3">
                  {moment(item?.schedule_from).format("ddd, DD MMM YYYY")}
                </div>
              </div>
            </div>
            <div className="h-[50px] w-[220px] flex flex-col justify-between">
              <div className="text-[12px] text-[#5d6561]">{t("To")}</div>
              <div className="text-[12px] flex items-center w-full">
                <img
                  alt="calender"
                  src={require("../assets/calender.png")}
                  className="w-[16px] h-[16px]"
                />
                <div className="text-[16px] ml-3">
                  {moment(item?.schedule_to).format("ddd, DD MMM YYYY")}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between ">
            <div className="h-[50px] w-[220px] flex flex-col justify-between">
              <div className="text-[12px] flex items-center w-full">
                <img alt="clock" src={require("../assets/clock.png")} className="w-[16px] h-[16px]" />
                <div className="text-[16px] ml-3">
                  {moment(item?.startfrom, "HH:mm:ss").format("h:mm A")} -{" "}
                  {moment(item?.startto, "HH:mm:ss").format("h:mm A")}
                </div>
              </div>
            </div>
            <div className="h-[50px] w-[220px] flex flex-col justify-between">
              <div className="text-[12px] flex items-center w-full">
                <img alt="clock" src={require("../assets/clock.png")} className="w-[16px] h-[16px]" />
                <div className="text-[16px] ml-3">
                  {moment(item?.endfrom, "HH:mm:ss").format("h:mm A")} -{" "}
                  {moment(item?.endto, "HH:mm:ss").format("h:mm A")}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-center ">
            <div
              onClick={() => {
                setSelectedSwapRequest(item);
                setModalVisible(true);
                setIsSubmit(true);
              }}
              className={`w-[200px] h-[40px] rounded-[8px] bg-[#00a843] hover:bg-[#389365] text-[#fff] text-[16px] font-[700] flex items-center justify-center cursor-pointer`}
            >
              {t("Details")}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SwapRequests;
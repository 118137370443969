import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Import SVG files as React components
import { ReactComponent as LogoutIcon } from "../assets/logout.svg";
import { ReactComponent as DashboardIcon } from "../assets/dashboard.svg";
import { ReactComponent as CrudsIcon } from "../assets/cruds.svg";
import { ReactComponent as SwapIcon } from "../assets/swap.svg";
import { ReactComponent as HospitalIcon } from "../assets/Hospital.svg";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to get the current path
  const pathname = useParams();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isAdmin = useSelector((state: any) => state.user.isAdmin);
  const { t, i18n } = useTranslation();
  const isActive = (path: string) => location.pathname === path; // Function to check if the path is active

  return (
    <div className={`bg-[#282c2b] rounded-[16px] flex flex-col items-center py-5 ${isCollapsed ? "w-[60px]" : "w-[90px]"} transition-all duration-300`}>
      {/* Toggle Button */}
      <button
        className={`absolute top-3 right-3 text-white bg-gray-700 rounded-full p-1 md:hidden ${isCollapsed ? "block" : "hidden"}`}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {isCollapsed ? ">" : "<"}
      </button>

      <div className={`flex flex-col items-center justify-between ${isCollapsed ? "items-center" : "items-start"} transition-all duration-300`}>
        {/* Logo */}
        <img alt="logo" src={require("../assets/logo.png")} className={`w-[40px] md:w-[72px] ${isCollapsed ? "hidden" : "block"}`} />

        {/* Sidebar Links */}
        <div className={`flex flex-col items-center mt-4 ${isCollapsed ? "items-center" : "items-start"}`}>
        <div
            className={`mb-[10px] md:mb-[20px] rounded-[4px] w-[30px] h-[30px] md:w-[40px] md:h-[40px] flex items-center justify-center cursor-pointer ${
              isActive("/dashboard") ? "bg-[#00a843] hover:bg-[#389365]" : "bg-[#4a4e4d] hover:bg-[#389365]"
            }`}
            onClick={async () => {
              navigate(`/dashboard`);
            }}
          >
            <DashboardIcon className="w-[14px] md:w-[18px]" />
          </div>
          <div
            className={`mb-[10px] md:mb-[20px] rounded-[4px] w-[30px] h-[30px] md:w-[40px] md:h-[40px] flex items-center justify-center cursor-pointer ${
              isActive("/everyone") ? "bg-[#00a843] hover:bg-[#389365]" : "bg-[#4a4e4d] hover:bg-[#389365]"
            }`}
            onClick={async () => {
              navigate(`/everyone`);
            }}
          >
            <CrudsIcon className="w-[14px] md:w-[18px]" />
          </div>
          <div
            className={`mb-[10px] md:mb-[20px] rounded-[4px] w-[30px] h-[30px] md:w-[40px] md:h-[40px] flex items-center justify-center cursor-pointer ${
              isActive("/swap") ? "bg-[#00a843] hover:bg-[#389365]" : "bg-[#4a4e4d] hover:bg-[#389365]"
            }`}
            onClick={async () => {
              navigate(`/swap`);
            }}
          >
            <SwapIcon className="w-[14px] md:w-[18px]" />
          </div>
           {/* Uncomment when needed
          <div
            className={`mb-[10px] md:mb-[20px] rounded-[4px] w-[30px] h-[30px] md:w-[40px] md:h-[40px] flex items-center justify-center cursor-pointer ${
              isActive("/materials") ? "bg-[#00a843] hover:bg-[#389365]" : "bg-[#4a4e4d] hover:bg-[#389365]"
            }`}
            onClick={() => navigate("/materials")}
          >
            <img alt="materials" src={require("../assets/material.png")} className="w-[14px] md:w-[18px]" />
          </div> */}

          {isAdmin && (
            <div
            className={`mb-[20px] rounded-[4px] w-[40px] h-[40px] flex items-center justify-center cursor-pointer ${
              isActive("/cruds") ? "bg-[#00a843] hover:bg-[#389365]" : "bg-[#4a4e4d] hover:bg-[#389365]"
            }`}
              onClick={async () => {
                navigate(`/cruds`);
              }}
            >
              <HospitalIcon className="w-[18px]" />
            </div>
          )}
        </div>

        {/* Uncomment when needed
        <div
          className={`bg-[#4a4e4d] hover:bg-[#389365] rounded-[4px] w-[30px] h-[30px] md:w-[40px] md:h-[40px] flex items-center justify-center cursor-pointer ${isCollapsed ? "hidden" : "block"} transition-all duration-300`}
          onClick={async () => {
            Cookies?.remove("session");
            refresh();
          }}
        >
          <LogoutIcon className="w-[14px] md:w-[18px]" />
        </div>
        */}
      </div>
    </div>
  );
};

export default Sidebar;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Schedule from "../../components/schedule";
import fetchWithToken from "../../utils/api";
import ScheduleTable from "../../components/scheduleMonth";
import UpComingShifts from "../../components/upComingShifts";
import SwapRequests from "../../components/SwapRequests";

const Dashboard = () => {
  const [nextShift, setNextShift] = useState<any>(null);
  const [countdown, setCountdown] = useState("");
  const { t, i18n } = useTranslation();
  const fetchNextShift = async () => {
    try {
      const data = await fetchWithToken("/schedules/upcoming", {
        method: "GET",
      });
      setNextShift(data?.data[0]);
    } catch (error) {
      console.error("Failed to fetch next shift:", error);
    }
  };

  useEffect(() => {
    fetchNextShift();
  }, []);

  useEffect(() => {
    if (!nextShift) return;
    const shiftStartTime = moment(`${nextShift?.date} ${nextShift?.shift?.start_time}`);
    const updateCountdown = () => {
      const now = moment();
      const duration = moment.duration(shiftStartTime.diff(now));
      if (duration.asSeconds() > 0) {
        const hours = String(duration.hours()).padStart(2, "0");
        const minutes = String(duration.minutes()).padStart(2, "0");
        const seconds = String(duration.seconds()).padStart(2, "0");
        setCountdown(`${hours}:${minutes}:${seconds}`);
      } else {
        setCountdown("00:00:00");
      }
    };
    const intervalId = setInterval(updateCountdown, 1000);
    updateCountdown();
    return () => clearInterval(intervalId);
  }, [nextShift]);

  return (
    <div className="w-full h-full flex-col flex flex-1 overflow-hidden px-[6px]">
      <div className="bg-[#9ad5a4] lg:h-[55px] h-auto flex flex-col md:flex-row items-center justify-center font-[500] text-[16px] md:text-[24px] rounded-[8px] mt-[4px] mb-[16px] px-4 py-2 md:py-0">
        <span>{t("NextShiftMessage")}</span>
        <span className="font-[700] mx-2">{countdown || 0}</span>
        <span>{t("NextShiftMessageh")}</span>
      </div>
      <div className="w-full h-full flex flex-1 lg:overflow-hidden overflow-y-auto lg:flex-row flex-col">
        <div className="flex flex-col min-w-[50%] max-w-full w-full lg:overflow-y-scroll scrollbar-hidden mr-[20px]">
          <Schedule />
          <ScheduleTable />
        </div>
        <div className="max-w-[30%] min-w-[420px] max-h-full">
          <div className="overflow-y-scroll scrollbar-hidden h-full">
            <UpComingShifts />
            <SwapRequests />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
